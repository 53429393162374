import React from 'react';
import TopHeader from '../header/topHeader';
import AboutUsBanner from '../aboutUsBanner/aboutUsBanner';
import Cyber from '../cyber/Cyber';
import AboutSpeciality from '../aboutSpeciality/aboutSpeciality';
import Footer from '../footer/Footer';

const AboutUs = () => {
    return (
        <div>
            <TopHeader />
            <div id="main">
                <AboutUsBanner />
                <Cyber />
                <AboutSpeciality />
                <Footer />

            </div>
        </div>
    )
}

export default AboutUs;

import axios from 'axios'
const CryptoJS = require("crypto-js");
const dataEncryptionKey = process.env.REACT_APP_DATA_ENCRYPTION_KEY;

export const ENV = {
    url: process.env.REACT_APP_SERVER_URL,
    languageApi: process.env.REACT_APP_LANGUAGE_API,
    invoicePath: process.env.REACT_APP_SERVER_INVOICE_PATH,
    uploadedImgPath: process.env.REACT_APP_UPLOADED_IMG_PATH,
    uploadedExtractPath: process.env.REACT_APP_UPLOADED_EXTRACT_PATH,
    // Headers
    Authorization: process.env.REACT_APP_AUTHORIZATION,
    xAuthToken: process.env.REACT_APP_X_AUTH_TOKEN,
    events: ["load", "mousemove", "mousedown", "click", "scroll", "keypress",],
    apiUrls: {
        cookieUrls: 'https://cookiesandyou.com/',
        restCountries: 'https://restcountries.com/v3.1/all',
        fireFoxUrl: 'https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen',
        internetExplorerUrl: 'https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies',
        chromeUrl: 'https://support.google.com/chrome/answer/95647?hl=de&hlrm=en',
        safariUrl: 'https://support.apple.com/de-de/guide/safari/sfri11471/mac',
        operaUrl: 'https://help.opera.com/de/latest/web-preferences/#cookies',
        whatsappUrl: 'https://www.whatsapp.com/legal/?eea=1#privacy-policy',
        paypalUrl: 'https://www.paypal.com/de/webapps/mpp/ua/privacy-full',
        buchhaltungsbutlerUrl: 'https://www.buchhaltungsbutler.de/',
        googlePrivacyUrl: 'https://www.google.com/intl/de/policies/privacy/',
        paypalUserAgreementUrl: 'https://www.paypal.com/de/webapps/mpp/ua/useragreement-full',
        paypalPrivacyWaxUrl: 'https://www.paypal.com/de/webapps/mpp/ua/privacywax-full',
        amazonPaymentUrl: 'https://payments.amazon.de/help/201751590',
        klarnaUrl: 'https://www.klarna.com/sofort/',
        klarnaSiteUrl: 'https://www.klarna.com/',
        molliePaymentUrl: 'https://www.mollie.com/legal/terms-payments-de',
        mollieUrl: 'https://www.mollie.com/',
        paypalTermsUrl: 'https://www.paypal.com/de/webapps/mpp/ua/pui-terms'
    },
    //check user is logged in or not
    checkUserAuth: function () {
        if (localStorage.getItem('attivitaEncryptedUser')) {
            return this.getUserKeys('status').status;
        } else {
            return false;
        }
    },
    //set user in local storage
    encryptUserData: (data) => {
        const userData = localStorage.getItem('attivitaEncryptedUser');
        if (userData) {
            const bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            let originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && originalData.callingCode) {
                data.callingCode = originalData.callingCode;
            }
            if (originalData && originalData.accessToken && !data.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify(data);
        const encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
        localStorage.setItem('attivitaEncryptedUser', encryptedUser);
        return true;
    },
    //return required keys
    getUserKeys: (keys = null) => {
        const userData = localStorage.getItem('attivitaEncryptedUser');
        if (userData) {
            const bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            let originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            let user = {};
            if (keys) {
                keys = keys.split(" ");
                for (const key in keys) {
                    const keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            }
            else {
                user = originalData;
            }
            return user;
        } else {
            return {};
        }

    },
    objectToQueryString: (body) => {
        const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
        return qs;
    },
    clearStorage: () => {
        localStorage.removeItem("close_time")
        localStorage.removeItem("open_time")
        localStorage.removeItem('attivitaEncryptedUser')
        localStorage.removeItem('coupon')
        localStorage.removeItem('cart')
        localStorage.removeItem('accessToken')
    },
    setLocalCart: (cart) => {
        const localCart = JSON.parse(localStorage.getItem("cart"))
        let newCart = []
        let selectedProduct = null

        if (localCart && Array.isArray(localCart)) {
            localCart.forEach((item) => {
                selectedProduct = {
                    productId: item?.productId,
                    name: item?.name,
                    shortDescription: item?.shortDescription,
                    image: item?.image,
                    variationName: item?.variationName,
                    variationId: item?.variationId,
                    quantity: item?.quantity,
                    price: item?.price,
                    salesPrice: item?.salesPrice,
                    discountPercentage: item?.discountPercentage
                }
                newCart.push(selectedProduct)
            })
        }

        if (cart?.items) {
            let addNew = true
            cart.items.forEach((item) => {
                if (newCart) {
                    newCart.forEach((items) => {
                        if (items.productId === item.productId && items.variationId === item.variationId) {
                            items.quantity += item.quantity
                            addNew = !addNew
                        }
                    })//eslint-disable-line
                }
                if (addNew) {
                    selectedProduct = {
                        productId: item.productId,
                        name: item.product.name,
                        shortDescription: item.product.shortDescription,
                        image: JSON.parse(item.product.Image),
                        variationName: item?.productvariation?.details.map((detail) => { return `${detail.attributeName}: ${detail.attributeValue}` }).join(' ,'),
                        variationId: item.variationId,
                        quantity: item.quantity,
                        price: item.unitSalesPrice,
                        // salesPrice: item.unitSalesPrice * item.quantity,
                        salesPrice: item.unitSalesPrice,
                        discountPercentage: 0,
                        totalLicenses: item?.productvariation?.skuCount
                    }
                    newCart.push(selectedProduct)
                }
            })
        }

        localStorage.setItem("cart", JSON.stringify(newCart))

    },
    uploadImage: (e, type = "image") => {
        const data = new FormData();
        data.append(type, e.target.files[0]);
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: `${ENV.url}staff/upload-image`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
                    'x-auth-token': process.env.REACT_APP_X_AUTH_TOKEN
                }
            })
                .then(data => {
                    resolve(data.data.data)
                })
                .catch(err => {
                    reject({ err })
                })
        })
    },
    limitOptions: [5, 10, 20, 50, 100, 150, 250],
}
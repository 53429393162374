import React, { useState, Fragment, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { beforeCheckout, getOrder } from "../checkout/checkout.action";
import { faArrowRight, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import TopHeader from '../header/topHeader';
import Footer from '../footer/Footer';
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./Thankyou.css";

const Thankyou = (props) => {
	const { lang } = useLanguage()
	const history = useNavigate()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	const [order, setOrder] = useState()

	useEffect(() => {
		props.getOrder(window.location.pathname.split('/')[2])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.checkout.getOrder) {
			if (props.checkout.order)
				setOrder(props.checkout.order)
			else {
				history('/')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkout.getOrder]);

	return (
		<Fragment>

			<TopHeader />
			<div id="main">
				<section className="checkout-form mt-5 mb-5">
					{
						order &&
						<Container>
							<Row>
								<Col lg="12" md="12" sm="12">
									<div className="main-checkout-wrapper">
										<div className="checkout-header mb-5 text-center">
											<h4 className="text-capitalize mb-3"> {translations['Thank you For Shopping With Attivita']}</h4>
											<h6>{translations['Your order number:']}<span className="text-order"> {order.order_nr}</span></h6>
											<p>{translations['Order confirmation email has been sent.']}</p>
										</div>
										<div className="checkout-container pt-5">
											<Row >
												<Col lg="6" md="6" sm="12">
													<div className="billing-info mb-4">
														<h5 className="text-capitalize">{translations['Billing Address']}</h5>
														<p>{order.shipping?.address.replace(/~/g, '  ')}</p>
													</div>
												</Col>
												<Col lg="6" md="6" sm="12">
													<div className="billing-info mb-4">
														<h5 className="text-capitalize">{translations['Information']}</h5>
														<p>{order.shipping?.name}</p>
														<p>{order.shipping?.email}</p>
														<p>{order.shipping?.mobile}</p>
													</div>
												</Col>
											</Row>
											<Row>
												<Col lg="12" md="12" sm="12">
													<div className="product-tbl-wrapper">
														<table className="theme-bordered-tbl product_tbl">
															<thead>
																<tr>
																	<th>{translations['Product Name']}</th>
																	<th>{translations['Quantity']}</th>
																	<th>{translations['Unit Price']}</th>
																	<th>{translations['Discount']}</th>
																	<th>{translations['Total Price']}</th>
																</tr>
															</thead>
															<tbody>
																{
																	order.items.map((item, index) => {
																		return (
																			<tr>
																				<td><div className="item-title checkout-p"><p>{item.item}</p></div></td>
																				<td><span className="value">{item.quantity}</span></td>
																				<td><span className="value">{order.currency.symbol} {parseFloat(item.salesPrice).toFixed(2)} </span></td>
																				<td><span className="value">{item.discountPercentage ? `${item.discountPercentage}%` : ( item?.discountAmount ? `${item?.discountAmount } ${order.currency.code}` : `0 ${order.currency.code}` )}</span></td>
																				<td><span className="value">{order.currency.symbol} {parseFloat(item.subTotal).toFixed(2)} {order.currency.code}</span></td>
																			</tr>
																		)
																	})
																}
															</tbody>
														</table>
													</div>
												</Col>
											</Row>
											<hr />
											<Row className="mt-5">
												<Col lg="7" md="6" xs="12">
												</Col>
												<Col lg="5" md="6" xs="12">
													<div className="d-flex flex-column h-100">
														<div className="checkout-header card-checkout-header">
															<h5>
																{translations['Cart']}
																<span className="price">
																	<FontAwesomeIcon icon={faCartShopping} />
																</span>
															</h5>
														</div>
														<div className="checkout-container checkout checkout-summary">
															<p>{translations['Sub Total']}
																<span className="price">
																	<b>
																		{order.currency.symbol} {parseFloat(order.subtotal).toFixed(2)} {order.currency.code}
																	</b>
																</span>
															</p>
															<p>{translations['Discount']}
																<span className="price">
																	<b>
																		{order.currency.symbol} {parseFloat(order.discountTotal).toFixed(2)} {order.currency.code}
																	</b>
																</span>
															</p>
															<p>	{order.vatLabel} ({order.vatPercentage} %)
																<span className="price">
																	<b>
																		{order.currency.symbol} {parseFloat(order.taxtTotal).toFixed(2)} {order.currency.code}
																	</b>
																</span>
															</p>
															<p>{translations['Total']}
																<span className="price">
																	<b>
																		{order.currency.symbol} {parseFloat(order.grandTotal).toFixed(2)} {order.currency.code}
																	</b>
																</span>
															</p>
														</div>
													</div>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
								<Col lg="12" md="12" xs="12">
									<div id="checkoutButton" className="mt-3">
										<Link to={`/shop/${lang}`} >
											<button type="button" className="btn shopBtn btn-success btn-block" >
												{translations['Continue Shopping']}
												<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
											</button>
										</Link>
									</div>
								</Col>
							</Row>
						</Container>
					}
				</section>
			</div>
			<Footer />
		</Fragment>
	)
}
const mapStateToProps = state => ({
	checkout: state.checkout
})
export default connect(mapStateToProps, { beforeCheckout, getOrder })(Thankyou);
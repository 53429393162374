import Dashboard from '../component/dashboard/dashboard';
import QuotationListing from '../component/dashboard/quotationListing';
import SalesOrdersListing from '../component/dashboard/salesOrdersListing';
import RefundedOrdersListing from '../component/dashboard/refundedOrdersListing';
import InvoicesListing from '../component/dashboard/invoicesListing';
import QuotationDetail from '../component/quotationDetail/quotationDetail';
import InvoiceDetail from '../component/invoiceDetail/invoiceDetail';
import Thankyou from '../component/thankYou/thankYou';
import UserProfile from '../component/userProfile/userProfile';
import VendorConfiguration from '../component/dashboard/vendorConfiguration';
import { PrivateRoute } from '../privateRoute';

const routes = [
    {
        path: "/dashboard/:de",
        exact: true,
        element:
            <PrivateRoute>
                <Dashboard />
            </PrivateRoute>
    },
    {
        path: "/quotation-listing/:de",
        exact: true,
        element:
            <PrivateRoute>
                <QuotationListing />
            </PrivateRoute>
    },
    {
        path: "/sales-order-listing/:de",
        exact: true,
        element:
            <PrivateRoute>
                <SalesOrdersListing />
            </PrivateRoute>
    },
    {
        path: "/refunded-order-listing/:de",
        exact: true,
        element:
            <PrivateRoute>
                <RefundedOrdersListing />
            </PrivateRoute>
    },
    {
        path: "/invoice-listing/:de",
        exact: true,
        element:
            <PrivateRoute>
                <InvoicesListing />
            </PrivateRoute>
    },
    {
        path: "/quotation-detail/:id/:de",
        exact: true,
        element:
            <PrivateRoute>
                <QuotationDetail />
            </PrivateRoute>
    },
    {
        path: "/invoice-detail/:id/:de",
        exact: true,
        element:
            <PrivateRoute>
                <InvoiceDetail />
            </PrivateRoute>
    },
    {
        path: "/user-profile/:de",
        exact: true,
        element:
            <PrivateRoute>
                <UserProfile />
            </PrivateRoute>
    },
    {
        path: "/thank-you/:id/:de",
        exact: true,
        element:
            <PrivateRoute>
                <Thankyou />
            </PrivateRoute>
    },
    {
        path: "/configuration/:de",
        exact: true,
        element:
            <PrivateRoute>
                <VendorConfiguration />
            </PrivateRoute>
    }
]

export default routes;
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import AboutBannerImg from '../../asset/image/about-us-banner.png'
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./aboutUsBanner.css";

const AboutUsBanner = () => {
    const { lang } = useLanguage()
    const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
    translations.setLanguage(lang);

    return (
        <section className="about-us-banner custom-container">
            <Container fluid>
                <Row className="align-items-center">
                    <Col lg="6" md="6" xs="12">
                        <div className="banner-text">
                            <h1>{translations["ABOUT US"]}</h1>
                            <p>{translations["WE CYBER-GUARD YOUR DATA, DEVICES & E-COMMUNICATIONS"]}</p>
                        </div>
                    </Col>
                    <Col lg="6" md="6" xs="12">
                        <div className="banner-img">
                            <img src={AboutBannerImg} className="img-fluid" alt="about-us-banner" title="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutUsBanner;
// LanguageContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [lang, setLang] = useState(localStorage.getItem('language') || 'en');

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage === 'en' || storedLanguage === 'de') {
            setLang(storedLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('language')]);

    const changeLanguage = (newLanguage) => {
        if (newLanguage === 'en' || newLanguage === 'de') {
            localStorage.setItem('language', newLanguage);
            setLang(newLanguage);
        }
    };

    return (
        <LanguageContext.Provider value={{ lang, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
}

import {
    EMPTY_ERRORS,
    GET_ERRORS,
    GET_BANNERS,
    BEFORE_BANNER,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS,
    };
};

export const beforeBanner = () => {
    return {
        type: BEFORE_BANNER,
    };
};

export const getBanners = () => (dispatch) => {
    dispatch(emptyError());
    const accessToken = localStorage.getItem('accessToken')
    fetch(`${ENV.url}setting/get-home-page-banner`, {
        method: "POST",
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': accessToken,
        },
    }).then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: GET_BANNERS,
                    payload: data.homeBanners,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import productImg2 from '../../asset/image/products-device.png'
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import { connect } from 'react-redux';
import { variationCurrencyFormat, currencyFormat, averageFormat, checkUserPackagePrice } from "../../util/function"
import { ENV } from "../../config/config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { useLanguage } from "../../context/language";
import "react-loading-skeleton/dist/skeleton.css";
import "./Product.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "./productCard";

const HomePageProduct = (props) => {
	const user = ENV.getUserKeys();
	const productsRef = useRef(null)

	const location = useLocation();
	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations, { logsEnabled: false });;
	translations.setLanguage(lang)

	const [loader, setLoader] = useState(true)
	const [productLoader, setProductLoader] = useState(true)
	const [manufacturers, setManufacturers] = useState([])
	const [manufacturer, setManufacturer] = useState(null)
	const [products, setProducts] = useState([])
	const [show, setShow] = useState(false);
	const currency = { symbol: "€", code: "EUR" } // eslint-disable-line no-unused-vars
	const pathName = ['/en', '/de'] // eslint-disable-line no-unused-vars

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	const fetchManufacturers = async (qs) => {
		let url = `${ENV.url}product/list-manufacturers`;
		if (qs)
			url += `?${qs}`
		axios({
			method: 'get',
			url: url,
			headers: {
				'Authorization': `Bearer ${ENV.Authorization}`,
				'x-auth-token': ENV.xAuthToken
			}
		})
			.then(data => {
				if (data.data.success) {
					setManufacturers(data.data.data.manufacturers)
					setLoader(false)
				} else {
				}
			})
			.catch(error => {
			})
	}
	const fetchProducts = async (qs) => {
		let url = `${ENV.url}product/list-products`;
		if (qs)
			url += `?${qs}`
		setProductLoader(true)
		axios({
			method: 'get',
			url: url,
			headers: {
				'Authorization': `Bearer ${ENV.Authorization}`,
				'x-auth-token': ENV.xAuthToken
			}
		})
			.then(data => {
				if (data.data.success) {
					setProducts(data.data.data.products)
					setProductLoader(false)
					productsRef.current.scrollIntoView();
				} else {
				}
			})
			.catch(error => {
			})
	}

	const handleManufacturer = (manufacturerId) => {
		const reseller = ENV.getUserKeys();
		const qs = ENV.objectToQueryString({ manufacturer: manufacturerId, resellerId: reseller?._id })

		props.setHideBestSeller(true)
		setManufacturer(manufacturerId)
		fetchProducts(qs)
	}
	useEffect(() => {
		window.scrollTo(0, 0)
		fetchManufacturers()
	}, [])

	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (user?.addresses && user?.addresses?.length > 0) {
				props.getVATforCountry(user?.addresses[0]?.country)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.siteSetting.getSettings]);

	return (
		<section className="products-section custom-container home-product-sec" ref={productsRef}>
			<Container fluid>
				<Row className="" >
					{manufacturer &&
						<Col lg="12" md="12" sm="12" >
							{
								!productLoader ?
									<Row>
										{
											products.length > 0 ?
												products.map((product, index_) => {
													let allowDisplay = true
													if (window.location.pathname.split('/')[1] === '' && index_ > 7) {
														allowDisplay = false
													}
													if (allowDisplay) {
														const productLink = `/product-detail/${product._id}/${lang}`;
														return (
															<ProductCard productLink={productLink} product_={product} handleShow={handleShow} index={index_}/>
														)
													}
												}) :
												''
										}
									</Row>
									:
									<Row>
										{
											[1, 2, 3, 4].map(k => (
												<Nav.Item key={k} className="link-unstyled col-xl-3 col-lg-4 col-md-4 col-sm-6" >
													<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
														<p>
															<Skeleton count={6} />
														</p>
														<p>
															<Skeleton count={3} />
														</p>
													</SkeletonTheme>
												</Nav.Item>
											))
										}
									</Row>
							}
						</Col>
					}
				</Row>
				<h3 className="vendor-head-sty mt-5 pt-5">{translations["Choose from Vendors"]}</h3>
				<Row className="mb-5">
					<Col lg="12" md="12" sm="12">
						<Nav variant="pills" className="row flex-row">
							{!loader ?
								manufacturers.length > 0 ?
									manufacturers.map((manufacturer, index) => {
										return (
											<Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4" key={index} onClick={() => { handleManufacturer(manufacturer._id) }}>
												<Nav.Link eventKey={`${index}-event`} className="link-unstyled  vendor-images px-0">
													<div className="products-card d-flex justify-content-center align-items-center flex-column position-relative">
														<div className="products-same-img vendor-img">
															<img src={manufacturer.image} className="img-fluid" alt={manufacturer.imageAltText ? manufacturer.imageAltText : manufacturer.name} title="" />
														</div>
													</div>

												</Nav.Link>
											</Nav.Item>
										)
									}) : <p className="text-danger">{translations['Currently no vendor is available pertaining to your requirement. For further queries, please contact us.']}</p>
								:
								<Fragment>
									{
										[1, 2, 3, 4, 5, 6].map(k => (
											<Nav.Item key={k} className="col-xl-2 col-lg-4 col-md-4 col-sm-4" >
												<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
													<p>
														<Skeleton count={3} />
													</p>
												</SkeletonTheme>
											</Nav.Item>
										))
									}
								</Fragment>
							}
						</Nav>
					</Col>
				</Row>
			</Container>
			<>
				<Modal show={show} onHide={handleClose} centered>
					<Modal.Header closeButton className="user-auth-modal-header">
					</Modal.Header>
					<Modal.Body className="user-auth-modal">
						{
							localStorage.getItem('attivitaEncryptedUser') ?
								<>
									<div className="d-flex justify-content-center mb-4">
										<FontAwesomeIcon icon={faWarning} className="warning-icon" />
									</div>
									{translations['Your account is not active. Kindly contact admin to verify your account.']}
								</>
								:
								<>
									<div className="d-flex justify-content-center mb-4">
										<FontAwesomeIcon icon={faWarning} className="warning-icon" />
									</div>
									{translations['here']} <Link to={`/login/${lang}`}>{translations['login']}</Link> {translations['go to login to see all product details']}
								</>
						}
					</Modal.Body>

				</Modal>
			</>
		</section>
	)
}

const mapStateToProps = state => ({
	errors: state.errors,
	siteSetting: state.siteSetting
});
export default connect(mapStateToProps, { getVATforCountry })(HomePageProduct);

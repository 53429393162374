import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ShopBannerImg from '../../asset/image/shop-banner.png'
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./ShopBanner.css";

const ShopBanner = () => {
	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	return (
		<section className="shop-banner custom-container">
			<Container fluid>
				<Row className="align-items-center">
					<Col lg="6" md="6" xs="12">
						<div className="banner-text">
							<h3>{translations['ALL-IN-ONE']}</h3>
							<h1>{translations['TRUE CYBER PROTECTION']}</h1>
						</div>
					</Col>
					<Col lg="6" md="6" xs="12">
						<div className="banner-img">
							<img src={ShopBannerImg} className="img-fluid" alt="laptop" title="" />
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default ShopBanner;
import React, { useState } from 'react';
import TopHeader from '../header/topHeader';
import ShopBanner from '../shopBanner/shopBanner';
import Search from '../search/Search';
import Product from '../product/Product';
import Footer from '../footer/Footer';

const Shop = () => {
    const [searchProductsFilter, setState] = useState('');
    const changeSelected = (val) => setState({ selected: val })
	const [sort, setSort] = useState()

    return (
        <div >
            <TopHeader />
            <div id="main">
                <ShopBanner />
                <Search changeSelected={(val) => { changeSelected(val) }} setSort={setSort} sort={sort}/>
                <Product searchProductsFilter={searchProductsFilter} setSort={setSort} sort={sort}/>
                <div className='p-3'></div>
                <Footer />
            </div>
        </div>
    )
}

export default Shop;

import { Navigate } from "react-router-dom";
import { ENV } from './config/config';
import { useLanguage } from "./context/language";

export const PrivateRoute = ({ children }) => {
    const { lang } = useLanguage()
    const { customername } = ENV.getUserKeys();
    console.log('customername: ', customername);
    if (!customername) {
        return <Navigate to={`/${lang ||'en'}`} />
    }
    return children
};
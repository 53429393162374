import React from 'react';
import TopHeader from '../header/topHeader';
import Banner from '../banner/banner';
import HomePageProduct from '../product/homePageProduct';
import BestSellerProductSlider from '../product/bestSellerProductSlider';
import ProductBtn from '../productBtn/productBtn';
import FAQ from '../faq/Faq';
import Footer from '../footer/Footer';

const Landing = () => {
    const [hideBestSellers, setHideBestSeller] = React.useState(false)
    return (
        <div>
            <TopHeader />
            <div id="main" className='overflow-x-hidden'>
                <Banner />
                {!hideBestSellers && <BestSellerProductSlider  />}
                <HomePageProduct setHideBestSeller={setHideBestSeller}/>
                <ProductBtn />
                <FAQ />
                <Footer />
            </div>
        </div>
    )
}

export default Landing;

import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import TopHeader from '../header/topHeader';
import Footer from '../footer/Footer';
import { ENV } from "../../config/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { currencyFormat } from "../../util/function"
import Select from 'react-select';
import { beforeCheckout, getCountries, placeOrder, getUser } from "./checkout.action";
import { beforeCart, addToUserCart } from "../cart/cart.action";
import productImg2 from '../../asset/image/products-device.png'
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import FullPageLoader from '../fullPageLoader/fullPageLoader'
import { useLanguage } from "../../context/language";
import "./Checkout.css";

const Checkout = (props) => {
	const userData = ENV.getUserKeys();
	const myRef = useRef(null)
	const history = useNavigate();

	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	const currency = { symbol: "€", code: "EUR" }
	const [billingData, setBillingData] = useState({
		agreementCheck: '',
		fullname: '',
		email: '',
		phone: '',
		address: '',
		country: '',
		city: '',
		zipCode: ''
	})
	const [msg, setMsg] = useState({
		fullname: '',
		email: '',
		phone: '',
		address: '',
		country: '',
		city: '',
	})
	const [loader, setLoader] = useState(false)
	const [agreementCheck, setAgreementCheck] = useState(false)
	const [country, setCountry] = useState([])
	const [vatPercentage, setVatPercentage] = useState(props.siteSetting.getCountry ? props.siteSetting.vat : props.siteSetting.getSettings ? props.siteSetting.settings.vatPercentage : 19)
	const [vatLabel, setVatLabel] = useState('VAT')
	const coupon = JSON.parse(localStorage.getItem("coupon")) ? JSON.parse(localStorage.getItem("coupon")) : {}
	const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [])
	const cartTotal = JSON.parse(localStorage.getItem("cartTotal")) ? JSON.parse(localStorage.getItem("cartTotal")) : [] // eslint-disable-line no-unused-vars
	const [billingAddressOptions, setBillingAddressOptions] = useState([])
	const [selectedBillingAddress, setSelectedBillingAddress] = useState()

	const placeOrder = () => {
		let error = false
		if (userData._id) {
			
			if (!agreementCheck || !billingData.fullname || !billingData.email || !selectedBillingAddress) {
				setMsg({
					agreementCheck: !agreementCheck ? translations['Agree to terms and conditions'] : '',
					fullname: !billingData.fullname ? translations['Fullname is required'] : '',
					email: !billingData.email ? translations['Email is required'] : '',
					phone: !billingData.phone ? translations['Phone is required'] : '',
					address: '',
					country: '',
					city: '',
					selectAddress: !selectedBillingAddress ? translations['Information is missing. Please enter your address in your profile.'] : '',
				})
				error = true
			} else {
				setMsg({
					agreementCheck: '',
					fullname: '',
					email: '',
					phone: '',
					address: '',
					country: '',
					city: '',
					selectAddress: '',
				})
			}
		}
		else {
			if (!agreementCheck || !billingData.fullname || !billingData.email || !billingData.address || !billingData.country || !billingData.city) {
				setMsg({
					agreementCheck: !agreementCheck ? translations['Agree to terms and conditions'] : '',
					fullname: !billingData.fullname ? translations['Fullname is required'] : '',
					email: !billingData.email ? translations['Email is required'] : '',
					phone: !billingData.phone ? translations['Phone is required'] : '',
					address: !billingData.address ? translations['Address is required'] : '',
					country: !billingData.country ? translations['Country is required'] : '',
					city: !billingData.city ? translations['City is required'] : '',
					selectAddress: '',
				})
				error = true
			} else {
				setMsg({
					agreementCheck: '',
					fullname: '',
					email: '',
					phone: '',
					address: '',
					country: '',
					city: '',
					selectAddress: '',
				})
			}
		}
		if (!error) {
			const body = makeOrderRequestBody()

			setLoader(true)
			props.placeOrder(body)
		} else {
			myRef.current.scrollIntoView()
		}
	}

	const makeOrderRequestBody = () => {
		let products = [];
		let checkPackage = ENV.getUserKeys('packageId');
		cart.map(item => {
			let subtotal = (item.salesPrice * item.quantity)
			subtotal = subtotal - (subtotal * item.discountPercentage / 100)
			products.push({
				name: item.name,
				variationName: item.variationName,
				productID: item.productId,
				productVariationID: item.variationId,
				quantity: item.quantity,
				salesPrice: item.salesPrice,
				discountPercentage: item.discountPercentage,
				discountAmount: item.discountAmount,
				discountType: item.discountType,
				subTotal: subtotal
			})
			return item
		})
		if (userData._id) {	// Logged in User Checkout Params
			const params = {
				promotionCode: JSON.parse(localStorage.getItem("coupon"))?.promotionCode ? JSON.parse(localStorage.getItem("coupon"))?.promotionCode : null,
				emailLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'de',
				customer: userData._id,
				priceList: coupon._id ? coupon._id : null,
				vatPercentage: vatPercentage,
				vatLabel: vatLabel,
				products: products,
				subTotal: cartTotal.subTotal,
				taxTotal: cartTotal.taxTtoal,
				discountTotal: cartTotal.discount,
				grandTotal: cartTotal.grandTotal,
				status: userData.isPartner === 0 ? 0 : 1,    // Quotation
				currency: currency,    // Quotation
				billingData,
				cartTotal,
				userPackage: checkPackage?.packageId
			}
			
			params.customerAddress = selectedBillingAddress.label
			params.customerAddressID = selectedBillingAddress.value
			params.countryID = country.value
			return params

		} else {	// Guest Checkout Params
			return {
				promotionCode: JSON.parse(localStorage.getItem("coupon"))?.promotionCode ? JSON.parse(localStorage.getItem("coupon"))?.promotionCode : null,
				emailLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'de',
				customerAddress: `${billingData.address} ${billingData.city} ${billingData.country.label} ${billingData.zipCode}`,
				countryID: country.value,
				priceList: coupon._id ? coupon._id : null,
				vatPercentage: vatPercentage,
				vatLabel: vatLabel,
				products: products,
				subTotal: cartTotal.subTotal,
				taxTotal: cartTotal.taxTtoal,
				discountTotal: cartTotal.discount,
				grandTotal: cartTotal.grandTotal,
				status: 0,    // Quotation
				currency: currency,    // Quotation
				billingData,
				cartTotal
			}
		}
	}

	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (userData?.addresses && userData?.addresses?.length > 0) {
				props.getVATforCountry(userData?.addresses[0]?.country)
			}
			else {
				setVatPercentage(props.siteSetting.settings.vatPercentage)
				setVatLabel("VAT")
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.siteSetting.getSettings]);

	useEffect(() => {
		props.beforeCheckout()
		if (!cart || cart.length === 0) {
			history('/shop')
		}
		if (userData._id) {
			setBillingData({
				agreementCheck: '',
				fullname: userData.customername,
				email: userData.email,
				phone: userData.mobile,
				address: '',
				country: '',
				city: '',
				zipCode: ''
			})
			props.getUser(userData._id)
		}
		props.getCountries()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setCart(JSON.parse(localStorage.getItem("cart")))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem("cart")]);

	useEffect(() => {
		if (props.checkout.getCountries && props.checkout?.user?.data) {
			const filtered = props.checkout?.user?.data?.addresses?.map(address => (
				{
					label: `${address?.street ?? ''} ${address?.city ?? ''} ${address?.state ?? ''} ${address?.country ?? ''} ${address?.zipCode ?? ''}`,
					value: address._id,
				}))
			const billingAddressOptions_ = filtered.filter((el) => el.label !== null && el.label !== '   ');
			setBillingAddressOptions(billingAddressOptions_)
			setSelectedBillingAddress(billingAddressOptions_[0])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkout.getCountries, props.checkout.getUser]);
	useEffect(() => {
		if (props.checkout.getUser) {
			if (userData?.addresses && userData?.addresses?.length > 0) {
				props.getVATforCountry(userData?.addresses[0]?.country)
			}
			else {
				setVatPercentage(props.siteSetting.settings.vatPercentage)
				setVatLabel("VAT")
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkout.getUser]);

	useEffect(() => {
		if (props.siteSetting.getCountry) {
			setVatPercentage(props.siteSetting.vat)
			setVatLabel(props.siteSetting.vat_label)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.siteSetting.getCountry]);

	useEffect(() => {
		setLoader(false)
		if (props.checkout.orderPlaced) {
			let cart = localStorage.getItem("cart")
			cart = cart ? JSON.parse(cart) : [];
			if (userData) {	//If user is logged in 
				cart.map((item, index) => {
					cart[index].unitSalesPrice = cart[index].salesPrice
					cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
					return item; // Return the modified item
				})
				const cartParams = {
					userId: userData._id,
					isCheckout: true,
					items: cart
				}
				props.addToUserCart(cartParams)
			}
			localStorage.removeItem('coupon')
			localStorage.removeItem('cart')
			localStorage.removeItem('cartTotal')

			props.beforeCheckout()
			history(`/thank-you/${props.checkout.order._id}/${lang}`)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkout.orderPlaced]);
	useEffect(() => {
		if (props.checkout.getError)
			setLoader(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.checkout.getError]);


	return (
		<Fragment>
			<TopHeader />
			{loader && <FullPageLoader />}
			<section className="checkout-form mt-5 mb-5">
				<Container>
					<Row>
						<Col lg="12" md="12" sm="12">
							<div className="main-checkout-wrapper">
								{
									!userData?._id &&
									<Row>
										<Col lg="12" md="12" sm="12">
											<div className="text-center alert alert-danger">
												{translations['You are not logged in to the system. Kindly']} <Link to={`/login/${lang}`}>{translations['login']}</Link> {translations['to continue']}
											</div>
										</Col>
									</Row>
								}
								<div className="checkout-header mb-4">
									<h4 className="text-capitalize">{translations['Complete order']}</h4>
								</div>
								<div className="checkout-container" ref={myRef}>
									<h5>{translations['Terms & conditions and cancellation policy']}</h5>
									<h6>{translations['Please note the cancellation policy.']}</h6>
									<div className="d-flex align-items-center mb-5">
										<div className="custom-control custom-checkbox">
											<input type="checkbox" className="custom-control-input" id="defaultChecked" onClick={() => setAgreementCheck(!agreementCheck)} />
											<label className="custom-control-label inline" htmlFor="defaultChecked">{translations['I have read the']} <Link to={`/page/terms-of-service/${lang}`} target="_blank">{translations['terms and conditions']}</Link> {translations['and agree to them.']}<small className="error">*</small></label>
											{msg.agreementCheck && <><br /><small className="error">{msg.agreementCheck}</small></>}<br />
										</div>
									</div>
									{
										userData?._id &&
										<Row >
											<Col lg="6" md="6" sm="12">
												<div className="billing-info mb-4">
													<h5 className="text-capitalize">{translations['Personal Details']}</h5>
													<div id="billing_info" className="align-items-center">
														<label htmlFor="fname">{translations['Full Name']}<small>*</small></label>
														<input required='required' type="text" value={billingData.fullname} onInput={(event) => setBillingData({ ...billingData, fullname: event.target.value })} placeholder="John M. Doe" readOnly />
														{msg.fullname && <small className="error">{msg.fullname}</small>} <br />

														<label htmlFor="email">{translations['Email']}<small>*</small></label>
														<input required='required' type="text" value={billingData.email} onInput={(event) => setBillingData({ ...billingData, email: event.target.value })} placeholder="john@example.com" readOnly />
														{msg.email && <small className="error">{msg.email}</small>}<br />
														<label htmlFor="phone">{translations['Phone No']}</label>
														<input type="number" value={billingData.phone} onInput={(event) => setBillingData({ ...billingData, phone: event.target.value })} placeholder="4965874851" readOnly />
													</div>
												</div>
											</Col>
											<Col lg="6" md="6" sm="12">
												<div className="billing-info mb-4">
													<h5 className="text-capitalize">{translations['Billing Address']}</h5>
													<div id="billing_info" className="align-items-center">
														<p>{billingAddressOptions[0]?.label}</p>
													</div>
												</div>
											</Col>
										</Row>
									}
									<Row>
										<Col lg="12" md="12" sm="12">
											<div className="product-tbl-wrapper">
												<div className="table-responsive">
													<table className="theme-bordered-tbl product_tbl">
														<thead>
															<tr>
																<th>{translations['Product']}</th>
																<th>{translations['Product Name']}</th>
																<th>{translations['Quantity']}</th>
																<th>{translations['Unit Price']}</th>
																<th>{translations['Discount']}</th>
																<th>{translations['Total Price']}</th>
															</tr>
														</thead>
														<tbody>
															{
																cart.length > 0 ?
																	cart.map((item, index) => {
																		return (
																			<tr key={index}>
																				<td>
																					<div className="item-image d-flex flex-column align-items-start">
																						<Link target="_blank" title="View product" to="#.">
																							<img className="img-responsive" width="100" alt='' src={item.image ? item.image : productImg2} />
																						</Link>
																					</div>
																				</td>
																				<td>
																					<div className="item-title checkout-p"> <p>{item.name} {item.variationName}</p> </div>
																				</td>
																				<td><span className="value">{item.quantity}</span>
																				</td>
																				<td><span className="value">{currencyFormat(item.salesPrice, currency.code)}</span>
																				</td>
																				<td><span className="value">{item.discountPercentage ? `${item.discountPercentage}%` : ( item?.discountAmount ? `${item?.discountAmount } ${currency.code}` : `0 ${currency.code}` )}</span></td>
																				<td><span className="value">{currencyFormat((item.quantity * item.salesPrice).toFixed(2), currency.code)}</span>
																				</td>
																			</tr>
																		)
																	})
																	: ''
															}
														</tbody>
													</table>
												</div>
											</div>
										</Col>
									</Row>
									<hr />
									<Row className="mt-5">
										<Col lg="6" md="4" xs="12">
										</Col>
										<Col lg="6" md="8" xs="12">
											<div className="d-flex flex-column h-100">
												<div className="checkout-header card-checkout-header">
													<h5>
														{translations['Cart']}
														<span className="price">
															<FontAwesomeIcon icon={faCartShopping} />
														</span>
													</h5>
												</div>
												<div className="checkout-container checkout checkout-summary">
													<div className="summary-info">
														{cart && cart.length > 0 ?
															cart.map((item, index) => {
																return (
																	<>
																		<p key={index}>
																			<a href="#.">
																				{item.name}<br />
																				{item.variationName}
																			</a>
																			<span className="price">
																				{currencyFormat(item.salesPrice, currency.code)}
																			</span>
																		</p>
																		{index < cart.length - 1 && <hr />}
																	</>
																)
															}) : ''
														}
													</div>
													<p>{translations['Sub Total']}
														<span className="price">
															<b>
																{currencyFormat(cartTotal.subTotal, currency.code)}
															</b>
														</span>
													</p>
													<p>{translations['Discount']}
														<span className="price">
															<b>
																{currencyFormat(cartTotal.discount, currency.code)}
															</b>
														</span>
													</p>
													<p>
														{vatLabel} ({vatPercentage} %)
														<span className="price">
															<b>
																{currencyFormat(cartTotal.tax, currency.code)}
															</b>
														</span>
													</p>
													<p>{translations['Total']}
														<span className="price">
															<b>
																{currencyFormat(cartTotal.total, currency.code)}
															</b>
														</span>
													</p>
													{
														userData?._id &&
														<button type="button" className="btn main-btn btn-block mt-4" onClick={() => placeOrder()} >
															{translations['Place Order']}
														</button>
													}
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<Footer />
		</Fragment>
	)
}
const mapStateToProps = state => ({
	siteSetting: state.siteSetting,
	checkout: state.checkout
})

export default connect(mapStateToProps, { beforeCheckout, getCountries, placeOrder, beforeCart, addToUserCart, getUser, getVATforCountry })(Checkout);
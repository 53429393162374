import React from 'react'
import Header from './Header'
import AuthHeader from '../authHeader/authHeader'
import { ENV } from '../../config/config';

const topHeader = () => {
    const { customername } = ENV.getUserKeys();
    return (
        <>
            {customername ? <AuthHeader /> : <Header />}
        </>
    )
}

export default topHeader
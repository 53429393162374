import React from 'react';
import TopHeader from '../header/topHeader';
import ContactBanner from '../contactBanner/contactBanner';
import ContactForm from '../contactForm/contactForm';
import Footer from '../footer/Footer';

const ContactUs = () => {
    return (
        <div>
            <TopHeader />
            <div id="main">
                <ContactBanner />
                <ContactForm />
                <Footer />
            </div>
        </div>
    )
}
export default ContactUs;
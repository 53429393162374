const en = {
    "Choose from Vendors": "Choose from Vendors",
    "Our Products Range": "Our Products Range",
    "Our Best Products Range": "Our Best Products Range",
    "Bestseller": "Bestseller",
    "View All Products": "View All Products",
    "GET IN TOUCH": "GET IN TOUCH",
    "Send us Enquiries": "Send us Enquiries",
    "Skype": "Skype",
    "Location": "Location",
    "Name": "Name",
    "Email": "Email",
    "Phone Number": "Phone Number",
    "Subject": "Subject",
    "Message": "Message",
    "Home": "Home",
    "Shop": "Shop",
    "About Us": "About Us",
    "ABOUT US": "ABOUT US",
    "API Configuration": "API Configuration",
    "API CONFIGURATION": "API CONFIGURATION",
    "Contact Us": "Contact Us",
    "Login": "Login",
    "Become a Reseller": "Become a Reseller",
    "100% German Licenses": "100% German Licenses",
    "Partner Benefits": "Partner Benefits",
    "Don't miss any promotions": "Don't miss any promotions",
    "Our heart": "Our heart beats for our partners in the software business",
    "Our visions": "Our vision:",
    "Our vision": "Be the best or nothing. Attivita is a young, colorful and experienced team in the software business team in the software business. Our vision guides us every day to deliver only the best for our for our customers and partners. Especially important to us is an open and at any time transparent approach. We offer only authenticated products in our store We love the challenge of finding the right product and the best service for our partners and their customers and we set a high value on quality and origin of our products.origin of our product.",
    "Our business": "Our business is guided by values like:",
    "Excellence": "Excellence",
    "Excellences": "In order to deliver only the best to our customers and partners every day, we learn continuously from the best.",
    "Listen": "Listen",
    "Listens": "We actively listen to what our employees, colleagues and most importantly our customers and partners tell us.",
    "Sparring": "Sparring",
    "Sparrings": "We see our customers and partners as sparring partners, who provide us with optimal support moment of our joint journey, are provided with ideas and challenged to make their challenged to make their business more successful.",
    "Quality": "Quality",
    "Qualities": "We do not compromise on the quality of our software.Our hobbyhorse: Norton 360. The software offers comprehensive device security and a VPN for your privacy on the net. Many millions of people worldwide are already using the software to significantly improve their Internet security. The two most important features are dark web monitoring and blocking online threats,on average over nine million a day. In our 24/7 store our registered partners can order products at any time,which will be delivered by mail in a few moments. We are constantly expanding our product range and are open for any requests and suggestions - what we do not have now,we will get right away.We love software!",
    "WE CYBER-GUARD YOUR DATA, DEVICES & E-COMMUNICATIONS": "WE CYBER-GUARD YOUR DATA, DEVICES & E-COMMUNICATIONS",
    "In our 24/7 shop, our registered partners can order products at any time, which will be delivered by email in just a few moments. We are constantly expanding our range and are open to any inquiries and suggestions - if we don't have it now, we'll get it right away.": "In our 24/7 shop, our registered partners can order products at any time, which will be delivered by email in just a few moments. We are constantly expanding our range and are open to any inquiries and suggestions - if we don't have it now, we'll get it right away.",
    "We love software!": "We love software!",
    "How to become a reseller?": "How to become a reseller?",
    "How to buy and use the vouchers?": "How to buy and use the vouchers?",
    "For which products can vouchers be redeemed?": "For which products can vouchers be redeemed?",
    "Can discount codes be used?": "Can discount codes be used?",
    "How can an order be cancelled?": "How can an order be cancelled?",
    "The right program suit for your customers at the best price": "The right program suit for your customers at the best price",
    "Norton 360 - All-in-One Protection": "Norton 360 - All-in-One Protection",
    "Become a reseller now": "Become a reseller now",
    "Nothing in the cart. Click": "Nothing in the cart. Click",
    "here": "here",
    "to add product to cart": "to add product to cart",
    "Have a coupon": "Have a coupon",
    "Enter Coupon Code": "Enter Coupon Code",
    "Apply Coupon": "Apply Coupon",
    "Cart": "Cart",
    "Coupon is invalid": "Coupon is invalid",
    "Sub Total": "Sub Total",
    "Discount": "Discount",
    "VAT": "VAT",
    "Total": "Total",
    "Checkout": "Checkout",
    "Inclusive of": "Inclusive of",
    "Choose Variant": "Choose Variant",
    "Select Product Type": "Select Product Type",
    "Add To Cart": "Add To Cart",
    "Please select variation": "Please select variation",
    "Complete Order": "Complete Order",
    "Terms & conditions and cancellation policy": "Terms & conditions and cancellation policy",
    "Please note the cancellation policy.": "Please note the cancellation policy.",
    "I have read the terms and conditions and agree to them.": "I have read the terms and conditions and agree to them.",
    "I have read the": "I have read the",
    "terms and conditions": "terms and conditions",
    "and agree to them.": "and agree to them.",
    "Personal Details": "Personal Details",
    "Billing Address": "Billing Address",
    "Full Name": "Full Name",
    "Select address": "Select address",
    "Select Country": "Select Country",
    "Country": "Country",
    "Address": "Address",
    "Phone No": "Phone No",
    "Add New Address": "Add New Address",
    "Product": "Product",
    "Extra Price Configuration": "Extra Price Configuration",
    "Product Price": "Product Price",
    "Product Name": "Product Name",
    "Total Cost" : "Total Cost",
    "Product Extra Price": "Product Extra Price",
    "Quantity": "Quantity",
    "Excl.VAT": "Excl.VAT",
    "Total Price": "Total Price",
    "Place Order": "Place Order",
    "You are not logged in to the system. Kindly": "You are not logged in to the system. Kindly",
    "to continue": "to continue",
    "login": "login",
    "City": "City",
    "Zip Code": "Zip Code",
    "Select from existing addresses": "Select from existing addresses",
    "Agree to terms and conditions": "Agree to terms and conditions",
    "Email is required": "Email is required",
    "Phone is required": "Phone is required",
    "Address is required": "Address is required",
    "Country is required": "Country is required",
    "City is required": "City is required",
    "Fullname is required": "Fullname is required",
    "Select the address": "Select the address",
    "Thank you For Shopping With Attivita": "Thank You For Shopping With Attivita",
    "Your order number:": "Your order number:",
    "Order confirmation email has been sent.": "Order confirmation email has been sent.",
    "Information": "Information",
    "Tax": "Tax",
    "Dashboard": "Dashboard",
    "Quotations": "Quotations",
    "Sales Orders": "Sales Orders",
    "Invoices": "Invoices",
    "Profile": "Profile",
    "Email Verified": "Email Verified",
    "Account": "Account",
    "Logout": "Logout",
    "Email Not Verified": "Email Not Verified",
    "Welcome Back": "Welcome Back",
    "Login to your account": "Login to your account",
    "Password": "Password",
    "Remember Me": "Remember Me",
    "Forget Password?": "Forget Password?",
    "Sign In": "Sign In",
    "Register As New Customer": "New here? Create an account.",
    "Hello User": "Hello User",
    "Register As New Reseller": "Register As New Reseller",
    "Company Name": "Company Name",
    "Re type Password": "Re type Password",
    "Register": "Register",
    "Prove you are a human": "Prove you are a human",
    "Already Have a Customer Account": "Already have an account? Login Here.",
    "Password is required": "Password is required",
    "Name is required": "Name is required",
    "Password must be 8 characters with atleast one character, capital and small alphabet with a number": "Password must be 8 characters with atleast one character, capital and small alphabet with a number",
    "Password does not match": "Password does not match",
    "Quotation": "Quotation",
    "Order Date": "Order Date",
    "Order Detail": "Order Detail",
    "Sale order pricing": "Sale order pricing",
    "Invoicing and Shipping Address": "Invoicing and Shipping Address",
    "Invoice": "Invoice",
    "Pricing": "Pricing",
    "Products": "Products",
    "Unit Price": "Unit Price",
    "Taxes": "Taxes",
    "Amount": "Amount",
    "No order found": "No order found",
    "Sales Order": "Sales Order",
    "Invoice Date": "Invoice Date",
    "Invoice Detail": "Invoice Detail",
    "Download Invoice": "Download Invoice",
    "Order": "Order",
    "Description": "Description",
    "Payment Status": "Payment Status",
    "Un Paid": "Un Paid",
    "Partially Paid": "Partially Paid",
    "Paid": "Paid",
    "Total Paid": "Total Paid",
    "Remaining Amount": "Remaining Amount",
    "Refunded Amount": "Refunded Amount",
    "Inform your email account, we will send you a link to reset and create your new password": "Inform your email account, we will send you a link to reset and create your new password",
    "Enter Your Email": "Enter Your Email",
    "Submit": "Submit",
    "Reset Password": "Reset Password",
    "Order placed successfully": "Order placed successfully",
    "Passwords do not match": "Passwords do not match",
    "Do you like cookies?": "Do you like cookies?",
    "We use cookies to ensure you get the best experience on our website.": "We use cookies to ensure you get the best experience on our website.",
    "Learn more": "Learn more",
    "I agree": "I agree",
    "Discount Percentage": "Discount Percentage",
    "Email successfully verified!": "Email successfully verified!",
    "Continue": "Continue",
    "Your account is not active yet. Kindly contact admin to continue.": " Your account is not active yet. Kindly contact admin to continue.",
    "Profile Updated": "Profile Updated",
    "Company Name is required": "Company Name is required",
    "Street is required": "Street is required",
    "(Required)": "(Required)",
    "(Optional)": "(Optional)",
    "Your account is not active. Kindly contact admin to verify your account.": "Your account is being reviewed. You'll received a confirmation once it's activated.",
    "Your Query has been submitted": "Your Query has been submitted",
    "Message is required": "Message is required",
    "Mobile is required": "Mobile is required",
    "Subject is required": "Subject is required",
    "Continue Shopping": "Continue Shopping",
    "Low License Product": "Low License Product",
    "You can\'t place order. Low license products are added in your cart": "You can\'t place order. Low license products are added in your cart", //eslint-disable-line
    "Low license products can\'t be added in your cart": "Low license products can\'t be added in your cart", //eslint-disable-line
    "Coupon is required": "Coupon is required",
    "ALL-IN-ONE": "ALL-IN-ONE",
    "TRUE CYBER PROTECTION": "TRUE CYBER PROTECTION",
    "Price: Asc": "Price: Asc",
    "Price: Desc": "Price: Desc",
    "Name: Asc": "Name: Asc",
    "Name: Desc": "Name: Desc",
    "Sort by": "Sort by",
    "Search": "Search",
    "License for this variant will be available soon": "License for this variant will be available soon",
    "Mobile": "Mobile",
    "State": "State",
    "Street": "Street",
    "Company URL": "Company URL",
    'Licenses Available': "Licenses Available",
    'Company Type': 'Reseller Type',
    'Company': 'Company',
    'No Manufacturers found': 'No Manufacturers found',
    'Currently no vendor is available pertaining to your requirement. For further queries, please contact us.': 'Currently no vendor is available pertaining to your requirement. For further queries, please contact us.',
    'No Products found': 'No Products found',
    'Currently no product is available pertaining to your requirement. For further queries, please contact us.': 'Currently no product is available pertaining to your requirement. For further queries, please contact us.',
    'Select City': 'Select City',
    'here': 'Here', //eslint-disable-line
    'go to login to see all product details': 'go to login to see all product details.',
    'Information is missing. Please enter your address in your profile.': 'Information is missing. Please enter your address in your profile.',
    'Company name is required': 'Company name is required',
    "Individual": "Individual",
    "Company url is required": "The company URL is incorrect",
    "Refunded": "Refunded",
    "Partially Refunded": "Partially Refunded",
    "Refunded Orders": "Refunded Orders",
    "Refunded Order": "Refunded Order",
    "Refund Date": "Refund Date",
    "New Password": "New Password",
    "Confirm Password": "Confirm Password",
    "Company Registration Extract": "Company Registration Extract",
    "Company VAT": "Company VAT",
    "Recommended Image Size": "Recommended Image Size",
    "Update": "Update",
    "Company vat is required": "Company vat is required",
    "View Extract": 'View Extract',
    "Zip Code is required": "Zip Code is required",
    "Send Email": "Send Email",
    "Company VAT is required": "Company VAT is required",
    "No quotation found": "No quotation found",
    "Welcome Admin": "Welcome Admin",
    "Redirecting to the site": "Redirecting to the site",
    "You are trying to access a reseller account.": "You are trying to access a reseller account.",
    "Sold Out": "Sold Out",
    "Enter a valid Mobile number": "Enter a valid Mobile number",
    "Coupon Applied on all products in cart": "Coupon Applied on all products in cart",
    "Coupon Applied, some items did not comply on the coupon": "Coupon Applied, some items did not comply on the coupon",
    "None of the cart items comply on the coupon": "None of the cart items comply on the coupon",
    "You have an in-valid product in your cart": "You have an in-valid product in your cart",
    "Copied":"Copied"
};
export default en
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import privateRoutes from '../src/privateRoute/privateRoute'
import { publicRoutes } from '../src/publicRoute/publicRoute'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ENV } from "./config/config"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/App.css";

const App = () => {
  const [lang, setLang] = useState(null)

  useEffect(() => {
    const url = window.location.href
    const lang = url.split('/').pop()
    if (lang === 'en' || lang === 'de') {
      localStorage.setItem("language", lang)
    } else {
      getGeo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGeo = async () => {
    if (localStorage.getItem('language')) {
      setLang(localStorage.getItem('language'))
    } else {
      const request = await fetch("https://ipinfo.io/json?token=d4aca20a95c4a6")
      const jsonResponse = await request.json()

      if (jsonResponse.country == "DE") {
        localStorage.setItem("language", "de")
        setLang('de')
      } else {

        localStorage.setItem("language", "en")
        setLang('en')

      }
    }
  }

  const astarikRoute = [{
    path: "*",
    element: < Navigate to={`/${lang ? 'lang' : 'en'}`} />
  }]
  const routers = createBrowserRouter([
    ...publicRoutes,
    ...privateRoutes,
    ...astarikRoute
  ]
  )
  return (
    <RouterProvider router={routers} />
  )
}
export default App;
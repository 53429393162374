import {
    EMPTY_ERRORS,
    GET_ERRORS,
    GET_SETTINGS,
    GET_COUNTRY_DATA,
    BEFORE_SETIINGS

} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS,
    };
};

export const beforeSettings = (data) => {
    return {
        type: BEFORE_SETIINGS,
    };
};

export const getSettings = () => (dispatch) => {
    dispatch(emptyError());
    fetch(`${ENV.url}setting/get`, {
        method: "GET",
        headers: {
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
    })
        .then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: GET_SETTINGS,
                    payload: data.settings,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getVATforCountry = (countryName) => (dispatch) => {
    dispatch(emptyError());
    fetch(`${ENV.url}user/get-vat-for-country/${countryName}`, {
        method: "GET",
        headers: {
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
    })
        .then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: GET_COUNTRY_DATA,
                    payload: data.VAT,
                    vat_label: data.vat_label
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const updateLanguage = (payload) => (dispatch) => {
    fetch(`${ENV.url}auth/update-language`, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
    })
};
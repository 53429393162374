import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SimpleSlider.css";

const AsNavFor = (props) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  return (
    <div>
      <Slider
        asNavFor={nav2}
        autoplay={true}
        autoplaySpeed={4000}
        pauseOnHover={true}
        ref={slider1Ref}
        className="product-slider"
      >
        {props.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt="" />
          </div>
        ))}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={slider2Ref}
        slidesToShow={props.images.length < 3 ? props.images.length : 3}
        swipeToSlide={true}
        focusOnSelect={true}
        className="product-bottom-slider"
        autoplay={true}
        autoplaySpeed={4000}
        pauseOnHover={true}
      >
        {
          props.images.map((image, index) => (
            <div key={index}>
              <img src={image} alt="" />
            </div>
          )
          )}
      </Slider>
    </div>
  );
};

export default AsNavFor;
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Col, Modal } from 'react-bootstrap';
import productImg2 from '../../asset/image/products-device.png'
import cartImage from '../../asset/image/shopping-cart.svg.svg'
import { getProduct, beforeProducts } from "./product.action";
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import { connect } from 'react-redux';
import { variationCurrencyFormat, currencyFormat, checkUserPackagePrice, averageFormat } from "../../util/function"
import { ENV } from "../../config/config";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "react-loading-skeleton/dist/skeleton.css";
import swal from 'sweetalert'
import "./Product.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import { beforeCart, addToUserCart } from '../cart/cart.action'
import Cart from "../cart/cart";
/**
 * 
 * @param { productLink, product_, handleShow, index} props 
 * @returns 
 */
const ProductCard = (props) => {
	const userData = ENV.getUserKeys();
	const { lang } = useLanguage()
	const { productLink, product_, handleShow, index } = props
	const initialPrice = averageFormat(product_?.initialPrice)
	const lastPrice = averageFormat(product_?.lastPrice)
	const translations = new LocalizedStrings(localizations, { logsEnabled: false });;
	const [cart, setaddToCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : []) // eslint-disable-line no-unused-vars
	const [variationError, setVariationError] = useState(false)
	const [cartTotals, setCartTotals] = useState(JSON.parse(localStorage.getItem("cartTotal")) ? JSON.parse(localStorage.getItem("cartTotal")) : { subTotal: 0, discount: 0, tax: 0, total: 0, }) // eslint-disable-line no-unused-vars
	translations.setLanguage(lang);
	const currency = {
		symbol: "€",
		code: "EUR"
	}
	const [vatPercentage, setVatPercentage] = useState(props.siteSetting.getCountry ? props.siteSetting.vat : props.siteSetting.getSettings ? props.siteSetting.settings.vatPercentage : 19)
	const [variationOptions, setVariationOptions] = useState([])
	const [selectedVariationData, setSelectedVariationData] = useState({
		id: '',
		price: '',
		quantity: 1,
		variationName: '',
		totalLicenses: 0
	})

	const [show, setShow] = useState(false);
	useEffect(() => {
		let variations = product_.variations
		let variationOptions_ = []
		if (lang === "de") {
			variationOptions_ = variations?.map(variation => ({
				label: variation.details.map((detail) => {
					return (
						`${detail.attributeNameDE}: ${detail.attributeValue}`
					)
				}).join(', '),
				value: variation._id,
				price: variation.salesPrice,
			}))
		} else {
			variationOptions_ = variations?.map(variation => ({
				label: variation.details.map((detail) => {
					return (
						`${detail.attributeName}: ${detail.attributeValue}`
					)
				}).join(', '),
				value: variation._id,
				price: variation.salesPrice,
			}))
		}
		setVariationOptions([...variationOptions_])
		if (variationOptions_.length === 1) {
			setSelectedVariationData({
				id: variationOptions_[0]._id,
				price: variationOptions_[0].price,
				quantity: 1,
				variationName: variationOptions_[0].label,
			})

		}
	}, [])
	const handleIncrement = () => {
		setSelectedVariationData({ ...selectedVariationData, quantity: parseInt(selectedVariationData.quantity) + 1 })
	}
	const handleDecrement = () => {
		if (selectedVariationData.quantity == 1) {
			return
		}
		setSelectedVariationData({ ...selectedVariationData, quantity: parseInt(selectedVariationData.quantity) - 1 })
	}
	const addToCart = () => {
		let selectedSKU = '';
		let cartData = localStorage.getItem("cart")
		let selectedVariationData_ = { ...selectedVariationData }
		if (product_.variations.length === 1) {
			selectedVariationData_ = { ...selectedVariationData_, id: product_.variations[0]._id }
		}
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);

		if (productWithId) {
			const productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: productWithId.quantity + selectedVariationData_.quantity,
				variationName: productWithId.variationName
			}
			selectedVariationData_.quantity = productWithId.quantity + selectedVariationData_.quantity
			setSelectedVariationData({ ...selectedVariationData, productData })
		}

		if (product_.skuCount >= selectedVariationData_.quantity) {
			if (product_.variations.length > 0) {
				if (!selectedVariationData_.id) {
					setVariationError(true)
				}
				else {
					setVariationError(false)
					setCart(product_, selectedVariationData_.price, selectedVariationData_.id, selectedVariationData_.quantity, selectedVariationData_.variationName)
					updateCart()
					let cart = localStorage.getItem("cart")
					cart = cart ? JSON.parse(cart) : [];
					if (userData?._id) {	//If user is logged in 
						cart.map((item, index) => {
							cart[index].unitSalesPrice = cart[index].salesPrice
							cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
							return item
						})

						const cartParams = {
							emailLanguage: localStorage.getItem('language'),
							userId: userData._id,
							isCheckout: false,
							items: cart
						}
						localStorage.setItem("cart", JSON.stringify(cart))
						props.addToUserCart(cartParams)
					}
					handleCartShow();
				}
			}
			else {
				setCart()
				updateCart()
				let cart = localStorage.getItem("cart")
				cart = cart ? JSON.parse(cart) : [];
				if (userData) {	//If user is logged in 
					cart.map((item, index) => {
						cart[index].unitSalesPrice = cart[index].salesPrice
						cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
						return item
					})
					const cartParams = {
						emailLanguage: localStorage.getItem('language'),
						userId: userData._id,
						isCheckout: false,
						items: cart
					}
					localStorage.setItem("cart", JSON.stringify(cart))
					props.addToUserCart(cartParams)
				}
				handleCartShow();
			}
		}
		else {
			const emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product_,
				variationName: selectedVariationData_.variationName,
				licenseCount: product_.skuCount,
				quantity: selectedVariationData_.quantity,
			}
			props.addToUserCart(emailData)
			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});
		}
	}

	const setCart = () => {
		let cart = localStorage.getItem("cart")
		cart = cart ? JSON.parse(cart) : [];
		let selectedVariationData_ = selectedVariationData
		if (product_.variations.length == 1) {
			selectedVariationData_ = { ...selectedVariationData_, id: product_.variations[0]._id }
		}
		let addNew = true
		cart.forEach(item => {
			if (item.productId === product_._id && item.variationId === selectedVariationData_.id) {
				item.quantity = selectedVariationData_.quantity
				addNew = !addNew
			}
		})
		if (addNew) {
			const selectedProduct = {
				productId: product_._id,
				name: product_.name,
				shortDescription: product_.shortDescription,
				image: JSON.parse(product_.Image),
				variationName: selectedVariationData_.variationName ? selectedVariationData_.variationName : '',
				variationId: selectedVariationData_.id ? selectedVariationData_.id : '',
				quantity: selectedVariationData_.quantity,
				price: selectedVariationData_.price + (selectedVariationData_.price * vatPercentage / 100),
				salesPrice: checkUserPackagePrice(product_?._id, selectedVariationData_.price),
				discountPercentage: 0,
				licenses: product_.skuCount,
				totalLicenses: product_.skuCount
			}
			cart.push(selectedProduct)
		}
		localStorage.setItem("cart", JSON.stringify(cart))
	}

	const updateCart = () => {
		let subTotal = 0
		let discount = 0
		let tax = 0
		let total = 0
		cart.map((item) => {
			let subTotal_ = item.quantity * item.salesPrice		//	SalesPrice * Quantity
			subTotal += subTotal_
			const discountPercentage = item.discountPercentage ? item.discountPercentage : 0	// DiscountPercentage
			const discountedPrice = subTotal_ - (subTotal_ * discountPercentage / 100)	// (SalesPrice * Quantity) - Discount
			const taxAmount = (discountedPrice * vatPercentage / 100)
			discount += subTotal_ * discountPercentage / 100
			item.price = discountedPrice + taxAmount
			tax += taxAmount
			return item
		})
		total = subTotal - discount + tax
		setCartTotals({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		})
		localStorage.setItem('cartTotal', JSON.stringify({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		}))
		if (userData?._id) {	//If user is logged in 
			cart.map((item, index) => {
				cart[index].unitSalesPrice = cart[index].salesPrice
				cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
				return item
			})
			const cartParams = {
				userId: userData._id,
				isCheckout: false,
				items: cart
			}
			props.addToUserCart(cartParams)
		}
	}
	const handleCartClose = () => {
		setShow(false)
		setSelectedVariationData({ ...selectedVariationData, quantity: 1 })
	}
	const handleCartShow = () => setShow(true);
	return (
		<>
			<Col lg={4} md={6} className="link-unstyled mb-3">
				<div className="products-card all-products-card flex-column position-relative">
					<div className="product-tags">
						<span className={product_.skuCount > 0 ? "" : "soldOut"}>
							{product_.skuCount > 0 ? `${localStorage.getItem('attivitaEncryptedUser') ? product_.skuCount : ''} ${translations["Licenses Available"]}` : `${translations["Sold Out"]}`}
						</span>
						{
							product_.bestSeller && <span>
								Best Seller
							</span>
						}
					</div>
					<Link to={ENV.checkUserAuth() && productLink} onClick={!ENV.checkUserAuth() && handleShow} key={index}>
						<div className="products-same-img">
							<img src={product_.Image && JSON.parse(product_.Image) ? JSON.parse(product_.Image) : productImg2} className="img-fluid" alt={product_.imageAltText ? product_.imageAltText : product_.name} />
						</div>
					</Link>

					<div className="products-card-text mb-3">
						<h5 title={product_.name} className="text-capitalize">{product_.name}</h5>
						{ENV.checkUserAuth() &&
							<span id="product-price">
								{product_.initialPrice == product_.lastPrice ? currencyFormat(checkUserPackagePrice(product_._id, initialPrice), currency.code, currency.symbol) : ''}
								{product_.initialPrice < product_.lastPrice ? variationCurrencyFormat(initialPrice, lastPrice, currency.code, currency.symbol, product_._id) : ''}
							</span>
						}
					</div>
					{
						ENV.checkUserAuth() &&
						<div className={`card-select-wrapper`}>
							{
								variationOptions.length === 1 ?
									""
									// <p>{variationOptions[0].label}</p>
									:
									<Select className="card-select mb-2"
										onChange={(option) => setSelectedVariationData({ ...selectedVariationData, id: option.value, price: option.price, variationName: option.label })}
										options={variationOptions}
									/>
							}
							{
								variationError &&
								<small className="error">{translations['Please select variation']}</small>
							}
							<div className="card-quantity">
								<strong>Quantity</strong>
								<FontAwesomeIcon className="quantity-icon" icon={faMinus} onClick={() => handleDecrement()} />
								<input
									type="text"
									name="quantity"
									min="1"
									max="9999"
									onKeyPress={(event) => event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57)}
									id="quantity"
									value={selectedVariationData.quantity}
									onChange={(e) => {
										let val = e.target.value !== "" ? parseInt(e.target.value) : 1
										if (isNaN(val)) {
											return
										}else{
											setSelectedVariationData({ ...selectedVariationData, quantity: val < 1 ? 1 : val })
										}
									}
									}
								></input>
								<FontAwesomeIcon className="quantity-icon" icon={faPlus} onClick={() => handleIncrement()} />
							</div>
						</div>
					}
					{
						ENV.checkUserAuth() &&
						<div className={`add-to-cart-div`} onClick={() => addToCart()}>
							<img src={cartImage} alt="Attivita Cart" />
						</div>
					}

				</div>
				{/* </Link> */}
			</Col>
			<Modal
				show={show}
				onHide={handleCartClose}
				className="cart-modal">
				<Modal.Header closeButton>
					<h4 className="mb-0"><span className="glyphicon glyphicon-shopping-cart"></span> <b>Cart</b></h4>
				</Modal.Header>
				<Modal.Body>
					<Cart />
				</Modal.Body>
			</Modal>
		</>
	)
}

const mapStateToProps = state => ({
	errors: state.errors,
	product: state.product,
	siteSetting: state.siteSetting
});
export default connect(mapStateToProps, { beforeProducts, getProduct, getVATforCountry, beforeCart, addToUserCart })(ProductCard);
import React, { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ErrorImg from '../../asset/image/error404.png'
import TopHeader from '../header/topHeader';
import Footer from '../footer/Footer';
import "./NotFound.css";

const NotFound = () => {
    return (
        <Fragment>
            <TopHeader />
            <div id="main">
                <section className="page-not-find custom-container">
                    <Container fluid>
                        <Row>
                            <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                                <div className="page-not-find-img">
                                    <img src={ErrorImg} className="img-fluid" alt="error404" title="" />
                                </div>
                                <Link to="/">
                                    <button className="home-btn">Back To Home</button>
                                </Link>
                            </div>
                        </Row>
                    </Container>
                </section>
            </div>
            <Footer />
        </Fragment>
    )
}

export default NotFound;
import {
    EMPTY_ERRORS,
    GET_ERRORS,
    BEFORE_CHECKOUT,
    GET_COUNTRIES,
    ORDER_PLACED,
    GET_ORDER,
    GET_USER,
    GET_LANGUAGES
} from "../../redux/types";
import { ENV } from "../../config/config";
import swal from 'sweetalert'
import { handleResponse } from "../../util/helper";

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS,
    };
};

export const beforeCheckout = () => {
    return {
        type: BEFORE_CHECKOUT,
    };
};

export const getCountries = () => (dispatch) => {
    dispatch(emptyError());
    fetch(`${ENV.url}country/list`, {
        method: "GET",
        headers: {
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.xAuthToken,
        },
    }).then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: GET_COUNTRIES,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const placeOrder = (body) => (dispatch) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(emptyError());
    fetch(`${ENV.url}shop/place-order`, {
        method: "POST",
        headers: {
            'Authorization': ENV.Authorization,
            'Content-Type': "application/json",
            'x-auth-token': ENV.xAuthToken,
            'x-token': accessToken,
        },
        body: JSON.stringify(body)
    }).then(handleResponse)
        .then((data) => {
            if (data.success) {
                const langs = localStorage.getItem('language') ? localStorage.getItem('language') : 'de';
                swal({
                    text: langs === 'de' ? 'Bestellung erfolgreich aufgegeben' : 'Order placed successfully',
                    icon: "success",
                    button: "OK",
                });
                dispatch({
                    type: ORDER_PLACED,
                    payload: data,
                });
            } else {
                swal({
                    text: data.message,
                    icon: "warning",
                    button: "OK!",
                });
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const getOrder = (orderId) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(emptyError());
    const url = `${ENV.url}shop/get-order/${orderId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': accessToken,
        },
    }).then(handleResponse)
        .then(data => {
            if (data.success) {
                dispatch({
                    type: GET_ORDER,
                    payload: data.order
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data
                })
            }
        }).catch(error => {
            dispatch({
                type: GET_ERRORS,
                payload: error
            })
        })
};

export const getUser = (userId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}user/${userId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
    }).then(handleResponse)
        .then(data => {
            if (data) {
                dispatch({
                    type: GET_USER,
                    payload: data
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data
                })
            }
        }).catch(error => {
            dispatch({
                type: GET_ERRORS,
                payload: error
            })
        })
};

export const getLanguages = () => (dispatch) => {
    dispatch(emptyError());
    fetch(`${ENV.url}language/list`, {
        method: "GET",
        headers: {
            Authorization: ENV.Authorization,
            "x-auth-token": ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
    })
        .then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: GET_LANGUAGES,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};
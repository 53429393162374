import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ContactBannerImg from '../../asset/image/contact-banner.png'
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./ContactBanner.css";

const ContactBanner = () => {
	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	return (
		<section className="contact-us-banner custom-container">
			<Container fluid>
				<Row className="align-items-center">
					<Col lg="6" md="6" xs="12">
						<div className="banner-text">
							<h1>{translations["GET IN TOUCH"]}</h1>
						</div>
					</Col>
					<Col lg="6" md="6" xs="12">
						<div className="banner-img">
							<img src={ContactBannerImg} className="img-fluid" alt="Contact Banner" title="" />
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}


export default ContactBanner;
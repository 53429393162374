import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import productImg2 from '../../asset/image/products-device.png'
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import { connect } from 'react-redux';
import { variationCurrencyFormat, currencyFormat, averageFormat, checkUserPackagePrice } from "../../util/function"
import { ENV } from "../../config/config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { settings } from "../../util/helper";
import { useLanguage } from "../../context/language";
import "react-loading-skeleton/dist/skeleton.css";
import "./Product.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios'

const BestSellerProductSlider = (props) => {
	const user = ENV.getUserKeys();

	const location = useLocation();
	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations, { logsEnabled: false });;
	translations.setLanguage(lang)

	const [products, setProducts] = useState([])
	const [show, setShow] = useState(false);
	const [productsLoader, setProductLoader] = useState(false);
	const currency = { symbol: "€", code: "EUR" } // eslint-disable-line no-unused-vars
	const [pathName, setPathName] = useState(['/en', '/de']) // eslint-disable-line no-unused-vars

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const fetchBestSellerProducts = async (qs) => {
		let url = `${ENV.url}product/list-best-sellers-products`;
		if (qs)
			url += `?${qs}`
		axios({
			method: 'get',
			url: url,
			headers: {
				'Authorization': `Bearer ${ENV.Authorization}`,
				'x-auth-token': ENV.xAuthToken
			}
		})
			.then(data => {
				if (data.data.success) {
					setProducts(data.data.data.products)
					setProductLoader(true)
				} else {
				}
			})
			.catch(error => {
			})
	}

	useEffect(() => {
		const resellers = ENV.getUserKeys();
		const qsProduct = ENV.objectToQueryString({ bestSeller: true, resellerId: resellers?._id })
		fetchBestSellerProducts(qsProduct)
	}, [])

	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (user?.addresses && user?.addresses?.length > 0) {
				props.getVATforCountry(user?.addresses[0]?.country)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.siteSetting.getSettings]);


	return (
		<section className="products-section custom-container home-product-sec">
			<Container fluid>
				<h2 className="text-center">{translations.Bestseller}</h2>
				<Tab.Container>
					<h3 className="vendor-head-sty">{translations["Our Best Products Range"]}</h3>
					<Row className="">
						<Col lg="12" md="12" sm="12">
							{
								productsLoader ?
									products.length > 0 &&
									<Slider {...settings} className="home-banner-slider home-product-slider">
										{
											products.map((product, index_) => {

												let initialPrice = 0
												let lastPrice = 0
												initialPrice = averageFormat(product.initialPrice)
												lastPrice = averageFormat(product.lastPrice)
												const productLink = `/product-detail/${product._id}/${lang}`;
												return (
													<Link to={ENV.checkUserAuth() && productLink} className="link-unstyled slider-content-item" onClick={!ENV.checkUserAuth() && handleShow} key={index_}>
														<div className="products-card all-products-card d-flex justify-content-between align-items-center flex-column position-relative">
															<div className="product-tags">
																<span className={product.skuCount > 0 ? "" : "soldOut"}>
																	{product.skuCount > 0 ? `${localStorage.getItem('attivitaEncryptedUser') ? product.skuCount : ''} ${translations["Licenses Available"]}` : `${translations["Sold Out"]}`}
																</span>
																{
																	!pathName.includes(location.pathname) && product.bestSeller && <span>
																		Best Seller
																	</span>
																}
															</div>
															<div className="products-same-img">
																<img src={product.Image && JSON.parse(product.Image) ? JSON.parse(product.Image) : productImg2} className="img-fluid" alt={product.imageAltText ? product.imageAltText : product.name} />
															</div>
															<div className="products-card-text">
																<h5 title={product.name} className="text-capitalize">{product.name}</h5>
																{ENV.checkUserAuth() &&
																	<span id="product-price">
																		{product.initialPrice === product.lastPrice ? currencyFormat(checkUserPackagePrice(product._id, initialPrice), currency.code) : ''}
																		{product.initialPrice < product.lastPrice ? variationCurrencyFormat(initialPrice, lastPrice, currency.code, product?._id) : ''}
																	</span>
																}

															</div>
														</div>
													</Link>
												)
											})
										}
									</Slider>
									:
									<Slider {...settings} className="home-banner-slider home-product-slider">
										{
											[1, 2, 3, 4, 5, 6, 7].map((k) => {
												return (
													<Fragment key={k}>
														<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
															<p>
																<Skeleton count={7} />
															</p>
															<p>
																<Skeleton count={2} />
															</p>
														</SkeletonTheme>
													</Fragment>
												)
											})
										}
									</Slider>

							}
						</Col>
					</Row>
				</Tab.Container>
			</Container>
			<>
				<Modal show={show} onHide={handleClose} centered>
					<Modal.Header closeButton className="user-auth-modal-header">
					</Modal.Header>
					<Modal.Body className="user-auth-modal">
						{
							localStorage.getItem('attivitaEncryptedUser') ?
								<>
									<div className="d-flex justify-content-center mb-4">
										<FontAwesomeIcon icon={faWarning} className="warning-icon" />
									</div>
									{translations['Your account is not active. Kindly contact admin to verify your account.']}
								</>
								:
								<>
									<div className="d-flex justify-content-center mb-4">
										<FontAwesomeIcon icon={faWarning} className="warning-icon" />
									</div>
									{translations['here']} <Link to={`/login/${lang}`}>{translations['login']}</Link> {translations['go to login to see all product details']}
								</>
						}
					</Modal.Body>

				</Modal>
			</>
		</section>
	)
}

const mapStateToProps = state => ({
	errors: state.errors,
	product: state.product,
	siteSetting: state.siteSetting
});
export default connect(mapStateToProps, { getVATforCountry })(BestSellerProductSlider);

import React, { Fragment, useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TopHeader from '../header/topHeader';
import Footer from '../footer/Footer';
import SimpleSlider from '../simpleSlider/simpleSlider';
import { connect } from 'react-redux';
import { getProduct, beforeProducts } from "../product/product.action";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ENV } from "../../config/config";
import { variationCurrencyFormat, currencyFormat, averageFormat, checkUserPackagePrice } from "../../util/function"
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import { beforeCart, addToUserCart } from '../cart/cart.action'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import swal from 'sweetalert'
import FullPageLoader from '../fullPageLoader/fullPageLoader'
import Cart from "../cart/cart";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "react-loading-skeleton/dist/skeleton.css";
import "./ProductDetail.css";

const ProductDetail = (props) => {
	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations, { logsEnabled: false });;
	translations.setLanguage(lang);

	const history = useNavigate();
	const myRef = useRef(null)
	const userData = ENV.getUserKeys();

	const [product, setProduct] = useState()
	const [vatPercentage, setVatPercentage] = useState(props.siteSetting.getCountry ? props.siteSetting.vat : props.siteSetting.getSettings ? props.siteSetting.settings.vatPercentage : 19)
	const [variationError, setVariationError] = useState(false)
	const [loader, setLoader] = useState(false)
	const [cart, setaddToCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : []) // eslint-disable-line no-unused-vars
	const [cartTotals, setCartTotals] = useState(JSON.parse(localStorage.getItem("cartTotal")) ? JSON.parse(localStorage.getItem("cartTotal")) : { subTotal: 0, discount: 0, tax: 0, total: 0, }) // eslint-disable-line no-unused-vars
	const [licenses, setLicenses] = useState(0) // eslint-disable-line no-unused-vars
	const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'en') // eslint-disable-line no-unused-vars
	const [currency, setCurrency] = useState({ symbol: "€", code: "EUR" }) // eslint-disable-line no-unused-vars
	const [selectedVariationData, setSelectedVariationData] = useState({
		id: '',
		price: '',
		quantity: 1,
		variationName: '',
		totalLicenses: 0
	})
	const [variationOptions, setVariationOptions] = useState([])
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false)
		setSelectedVariationData({...selectedVariationData, quantity: 1})
	}
	const handleShow = () => setShow(true);

	const addToCart = () => {
		let selectedSKU = '';
		let count = 0;
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);

		if (productWithId) {
			const productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: productWithId.quantity + selectedVariationData.quantity,
				variationName: productWithId.variationName
			}
			setSelectedVariationData({ ...selectedVariationData, productData })
			selectedVariationData.quantity = productWithId.quantity + selectedVariationData.quantity
		}
		product?.variations?.map((itemVar, index) => {
			if (selectedVariationData.id === itemVar._id) {
				selectedSKU = itemVar._id;
			}
			return itemVar
		})
		if (selectedSKU) {
			product?.licenses?.map((itemLic, index) => {
				if (itemLic.productVariationId === selectedSKU) {
					count++
				}
				return itemLic
			})
		}
		if (count >= selectedVariationData.quantity) {
			if (product.variations.length > 0) {
				if (!selectedVariationData.id) {
					setVariationError(true)
				}
				else {
					setVariationError(false)
					setCart(product, selectedVariationData.price, selectedVariationData.id, selectedVariationData.quantity, selectedVariationData.variationName)
					updateCart()
					let cart = localStorage.getItem("cart")
					cart = cart ? JSON.parse(cart) : [];
					if (userData?._id) {	//If user is logged in 
						cart.map((item, index) => {
							cart[index].unitSalesPrice = cart[index].salesPrice
							cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
							return item
						})

						const cartParams = {
							emailLanguage: localStorage.getItem('language'),
							userId: userData._id,
							isCheckout: false,
							items: cart
						}
						setLoader(true)
						localStorage.setItem("cart", JSON.stringify(cart))
						props.addToUserCart(cartParams)
					}
					handleShow();
				}
			}
			else {
				setCart()
				updateCart()
				let cart = localStorage.getItem("cart")
				cart = cart ? JSON.parse(cart) : [];
				if (userData) {	//If user is logged in 
					cart.map((item, index) => {
						cart[index].unitSalesPrice = cart[index].salesPrice
						cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
						return item
					})
					const cartParams = {
						emailLanguage: localStorage.getItem('language'),
						userId: userData._id,
						isCheckout: false,
						items: cart
					}
					localStorage.setItem("cart", JSON.stringify(cart))
					props.addToUserCart(cartParams)
				}
				handleShow();
			}
		}
		else {
			const emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product,
				variationName: selectedVariationData.variationName,
				licenseCount: count,
				quantity: selectedVariationData.quantity,
			}
			props.addToUserCart(emailData)
			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});
		}
	}

	const setCart = () => {
		let cart = localStorage.getItem("cart")
		cart = cart ? JSON.parse(cart) : [];
		let addNew = true
		cart.forEach(item => {
			if (item.productId === product._id && item.variationId === selectedVariationData.id) {
				item.quantity = selectedVariationData.quantity
				addNew = !addNew
			}
		})
		if (addNew) {
			const selectedProduct = {
				productId: product._id,
				name: product.name,
				shortDescription: product.shortDescription,
				image: JSON.parse(product.Image),
				variationName: selectedVariationData.variationName ? selectedVariationData.variationName : '',
				variationId: selectedVariationData.id ? selectedVariationData.id : '',
				quantity: selectedVariationData.quantity,
				price: selectedVariationData.price + (selectedVariationData.price * vatPercentage / 100),
				salesPrice: checkUserPackagePrice(product?._id, selectedVariationData.price),
				discountPercentage: 0,
				licenses: licenses,
				totalLicenses: selectedVariationData?.totalLicenses
			}
			cart.push(selectedProduct)
		}
		localStorage.setItem("cart", JSON.stringify(cart))
	}

	const updateCart = () => {
		let subTotal = 0
		let discount = 0
		let tax = 0
		let total = 0
		cart.map((item) => {
			let subTotal_ = item.quantity * item.salesPrice		//	SalesPrice * Quantity
			subTotal += subTotal_
			const discountPercentage = item.discountPercentage ? item.discountPercentage : 0	// DiscountPercentage
			const discountedPrice = subTotal_ - (subTotal_ * discountPercentage / 100)	// (SalesPrice * Quantity) - Discount
			const taxAmount = (discountedPrice * vatPercentage / 100)
			discount += subTotal_ * discountPercentage / 100
			item.price = discountedPrice + taxAmount
			tax += taxAmount
			return item
		})
		total = subTotal - discount + tax
		setCartTotals({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		})
		localStorage.setItem('cartTotal', JSON.stringify({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		}))
		if (userData?._id) {	//If user is logged in 
			cart.map((item, index) => {
				cart[index].unitSalesPrice = cart[index].salesPrice
				cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
				return item
			})
			const cartParams = {
				userId: userData._id,
				isCheckout: false,
				items: cart
			}
			props.addToUserCart(cartParams)
		}
	}

	const incrementInAddToCar = async (value) => {
		let selectedSKU = '';
		let count = 0;
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
		if (productWithId) {
			setSelectedVariationData({ ...selectedVariationData, quantity: selectedVariationData.quantity + value })
		} else {
			setSelectedVariationData({ ...selectedVariationData, quantity: selectedVariationData.quantity + value })
		}
		product?.variations?.map((itemVar, index) => {
			if (selectedVariationData.id === itemVar._id) {
				selectedSKU = itemVar._id;
			}
			return itemVar
		})
		if (selectedSKU) {
			product?.licenses?.map((itemLic, index) => {
				if (itemLic.productVariationId === selectedSKU) {
					count++
				}
				return itemLic
			})
		}
		if (count >= selectedVariationData.quantity + 1) {
		}
		else {
			let cartData = localStorage.getItem("cart")
			cartData = cartData ? JSON.parse(cartData) : [];
			const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
			if (productWithId) {
				const productData = {
					id: productWithId.productId,
					price: productWithId.price,
					quantity: productWithId.quantity,
					variationName: productWithId.variationName
				}
				setSelectedVariationData({ ...selectedVariationData, productData })
			} else {
				setSelectedVariationData({ ...selectedVariationData, quantity: selectedVariationData.quantity })
			}
			const emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product,
				variationName: selectedVariationData.variationName,
				licenseCount: count,
				quantity: selectedVariationData.quantity,
			}
			props.addToUserCart(emailData)
			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});
		}
	}

	const bulkIncrementInAddToCar = async (value) => {
		if (isNaN(value)) {
			return
		}
		value = value < 1 ? 1 : value
		let selectedSKU = '';
		let count = 0;
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
		if (productWithId) {
			const productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: value,
				variationName: productWithId.variationName
			}
			setSelectedVariationData({ ...selectedVariationData, productData })
		} else {
			setSelectedVariationData({ ...selectedVariationData, quantity: value })
		}
		product?.variations?.map((itemVar, index) => {
			if (selectedVariationData.id === itemVar._id) {
				selectedSKU = itemVar._id;
			}
			return itemVar
		})
		if (selectedSKU) {
			product?.licenses?.map((itemLic, index) => {
				if (itemLic.productVariationId === selectedSKU) {
					count++
				}
				return itemLic
			})
		}
		if (count >= value) {
			setSelectedVariationData({ ...selectedVariationData, quantity: value })
		} else {
			let cartData = localStorage.getItem("cart")
			cartData = cartData ? JSON.parse(cartData) : [];
			const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
			if (productWithId) {
				const productData = {
					id: productWithId.productId,
					price: productWithId.price,
					quantity: value,
					variationName: productWithId.variationName
				}
				setSelectedVariationData({ ...selectedVariationData, productData })
			} else {
				setSelectedVariationData({ ...selectedVariationData, quantity: value })
			}
			const emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product,
				variationName: selectedVariationData.variationName,
				licenseCount: count,
				quantity: value,
			}
			props.addToUserCart(emailData)
			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});
		}
	}

	useEffect(() => {
		if (variationOptions.length > 0) {
			setSelectedVariationData({ ...selectedVariationData, id: variationOptions[0]?.value, price: variationOptions[0]?.price, variationName: variationOptions[0]?.label })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [variationOptions])

	useEffect(() => {
		if (!ENV.checkUserAuth()) {
			history(`/login/${localStorage.getItem('language')}`)
		}
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
		if (productWithId) {
			const productData = {
				id: productWithId.productId,
				price: productWithId.price,
				// quantity: productWithId.quantity,
				quantity: 1,
				variationName: productWithId.variationName
			}
			setSelectedVariationData(productData)
		}
		myRef.current.scrollIntoView()
		props.getProduct(window.location.pathname.split('/')[2])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.product.getproduct) {
			switch (language) {
				case 'de':
					props.product.product.shortDescription = props.product.product.shortDescriptionDE
					props.product.product.longDescription = props.product.product.longDescriptionDE
					break;
				default:
					break;
			}
			setProduct(props.product.product)
			let variationOptions_ = []
			if (lang === "de") {
				variationOptions_ = props.product.product.variations?.map(variation => ({
					label: variation.details.map((detail) => {
						return (
							`${detail.attributeNameDE}: ${detail.attributeValue}`
						)
					}).join(', '),
					value: variation._id,
					price: variation.salesPrice,
				}))
			} else {
				variationOptions_ = props.product.product.variations?.map(variation => ({
					label: variation.details.map((detail) => {
						return (
							`${detail.attributeName}: ${detail.attributeValue}`
						)
					}).join(', '),
					value: variation._id,
					price: variation.salesPrice,
				}))
			}
			setVariationOptions([...variationOptions_])
			setSelectedVariationData({ ...selectedVariationData, price: props.product.product.salesPrice, totalLicenses: props.product.product.licenses.length })
			props.beforeProducts()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.product.getproduct]);

	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (userData?.addresses && userData?.addresses?.length > 0) {
				props.getVATforCountry(userData?.addresses[0]?.country)
			}
			else {
				setVatPercentage(props.siteSetting.settings.vatPercentage)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.siteSetting.getSettings]);

	useEffect(() => {
		if (props.siteSetting.getCountry) {
			setVatPercentage(props.siteSetting.vat)
			updateCart()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.siteSetting.getCountry]);

	useEffect(() => {
		if (props.cart.cartAddedAuth) {
			props.beforeCart()
			setLoader(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cart.cartAddedAuth]);

	useEffect(() => {
		if (props.cart.cartNotAddedAuth) {
			swal({
				text: translations['Your account is not active yet. Kindly contact admin to continue.'],
				icon: "warning",
				button: "OK!",
			});
			localStorage.removeItem('coupon')
			localStorage.removeItem('cart')
			localStorage.removeItem('cartTotal')
			handleClose()
			props.beforeCart()
			setLoader(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cart.cartNotAddedAuth]);

	return (
		<Fragment>
			<TopHeader />
			<div id="main" ref={myRef}>
				{product ?
					<section className="content-section mt-5 mb-5 product-detail-page" id="shop-page">
						<form id="addToCart" method="post" >
							<input type="hidden" name="product_id" />
							<Container>
								<Row className="clearfix">
									<Col lg="6" md="12" xs="12">
										<Row>
											<Col lg="2" md="2" xs="2"></Col>
											<Col lg="8" md="12" xs="12">
												<div className="feature-product-img">
													<SimpleSlider images={product.eccomerceImages} />
												</div>
											</Col>
											<Col lg="2" md="2" xs="2"></Col>
										</Row>
									</Col>
									<Col lg="6" md="12" xs="12">
										<div className="product-details ">
											<div className="d-flex flex-row justify-content-between align-items-start product-title-price mb-2">
												<h3 className="d-flex justify-content-between align-items-center product_price_label font-weight-bold">
													{product.name}
												</h3>
												<span title="Price Inclusive Tax" className="price-area">
													{product.initialPrice === product.lastPrice ? currencyFormat(
														checkUserPackagePrice(product._id, averageFormat(product.initialPrice))) : ''}
													{product.initialPrice < product.lastPrice ? variationCurrencyFormat(averageFormat(product.initialPrice), averageFormat(product.lastPrice), currency.code, product?._id) : ''}
												</span>
											</div>
											<div className="product-details-bottom-section mt-3 mb-3 ps-lg-0 " dangerouslySetInnerHTML={{ __html: product.shortDescription && product.shortDescription !== "undefined" ? product.shortDescription : '' }}>
											</div>
											<div className="panel-group custom-panel-style variant-select-des mb-3" id="accordion">
												<h3 className="d-flex justify-content-between align-items-center product_price_label">{translations['Choose Variant']}</h3>
												<Select
													placeholder={translations['Select Product Type']}
													options={variationOptions}
													defaultValue={variationOptions[0]}
													isDisabled={variationOptions.length === 1 ? true : false}
													name="productType"
													onChange={(option) => {
														setSelectedVariationData({ ...selectedVariationData, id: option.value, price: option.price, variationName: option.label })
													}
													}
													value={variationOptions.length === 1 ? variationOptions[0] : variationOptions?.filter(option => option.value === selectedVariationData.id)} />												{
													variationError &&
													<small className="error">{translations['Please select variation']}</small>}
												<div id="variation-error" className="animated fadeInDown"></div>
											</div>
											<Row>
												<Col lg="12" md="12" xs="12">
													<div className="cart-quilty-incremnt">
														<button type="button" id="decrement" onClick={(event) => {
															if (selectedVariationData.quantity > 1)
															setSelectedVariationData({ ...selectedVariationData, quantity: selectedVariationData.quantity - 1 })
														}}>
															<FontAwesomeIcon icon={faMinus} />
														</button>
														<input type="text" name="quantity" onKeyPress={(event) => (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))} onChange={(event) => bulkIncrementInAddToCar(event.target.value !== "" ? parseInt(event.target.value) : 1)} min="1" value={selectedVariationData.quantity} maxLength="4" max="9999"
															id="quantity" />														<button type="button" id="increment" onClick={(event) => incrementInAddToCar(1)}>
															<FontAwesomeIcon icon={faPlus} />
														</button>
													</div>
													<div id="quantity-error" className="invalid-feedback animated fadeInDown"></div>
												</Col>
												<Col lg="6" md="12" xs="12" className="button-center cart-btn-wrap">
													<button type="button" className={lang === "de" ? "main-btn-checkout mb-5" : "main-btn mb-5"} onClick={() => addToCart()}>{translations['Add To Cart']}</button>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
								<Row className="clearfix">
									<Col lg="12" md="12" xs="12">
										<hr />
									</Col>
									<Col lg="12" md="12" xs="12" className="product-long-description-area" dangerouslySetInnerHTML={{ __html: product.longDescription && product.longDescription !== "undefined" ? product.longDescription : '' }}>
									</Col>
								</Row>
							</Container>
						</form>
					</section>
					:
					<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
						<section className="content-section mt-5 mb-5 product-detail-page" id="shop-page">
							<form id="addToCart" method="post" >
								<input type="hidden" name="product_id" />
								<Container>
									<Row className="clearfix">
										<Col lg="6" md="12" xs="12">
											<Row>
												<Col lg="2" md="2" xs="2"></Col>
												<Col lg="8" md="12" xs="12">
													<div className="feature-product-img">
														<Skeleton height={250} width={250} />
													</div>
												</Col>
												<Col lg="2" md="2" xs="2"></Col>
											</Row>
										</Col>
										<Col lg="6" md="12" xs="12">
											<div className="product-details">
												<div className="d-flex flex-row justify-content-between align-items-start product-title-price mb-2">
													<h3 className="d-flex justify-content-between align-items-center product_price_label font-weight-bold">
														<Skeleton count={2} />
													</h3>
													<span title="Price Inclusive Tax" className="price-area">
														<Skeleton count={2} />
													</span>
												</div>
												<small className="vat d-flex justify-content-end w-100">
													<Skeleton count={1} /></small>
												<div className="product-details-bottom-section mt-3 mb-3 ps-lg-0 " >
													<Skeleton count={2} />
												</div>
												<div className="panel-group custom-panel-style mb-5" id="accordion">
													<Skeleton count={3} />
												</div>
												<Row>
													<Col lg="12" md="12" xs="12">
														<div className="cart-quilty-incremnt">
															<Skeleton count={5} />
														</div>
														<div id="quantity-error" className="invalid-feedback animated fadeInDown"></div>
													</Col>
													<Col lg="6" md="12" xs="12" className="button-center">
														<Skeleton count={1} />
													</Col>
												</Row>
											</div>
										</Col>
									</Row>
									<Row className="clearfix">
										<Col lg="12" md="12" xs="12">
											<hr />
										</Col>
										<Col lg="12" md="12" xs="12">
											<Skeleton count={6} />
										</Col>
									</Row>
								</Container>
							</form>
						</section>
					</SkeletonTheme>
				}
				{loader && <FullPageLoader />}
			</div>
			<Footer />
			<Modal
				show={show}
				onHide={handleClose}
				className="cart-modal">
				<Modal.Header closeButton>
					<h4 className="mb-0"><span className="glyphicon glyphicon-shopping-cart"></span> <b>Cart</b></h4>
				</Modal.Header>
				<Modal.Body>
					<Cart />
				</Modal.Body>
			</Modal>
		</Fragment>
	)
}

const mapStateToProps = state => ({
	errors: state.errors,
	siteSetting: state.siteSetting,
	product: state.product,
	cart: state.cart
})

export default connect(mapStateToProps, { beforeProducts, getProduct, addToUserCart, beforeCart, getVATforCountry })(ProductDetail);
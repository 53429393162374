import { BEFORE_LOGIN, REGISTER_ACTION, GET_ERRORS, LOGIN_ACTION, SET_CUSTOMER_PASSWORD, CART_RETURNED } from '../../redux/types';
import { ENV } from '../../config/config';
import swal from 'sweetalert'
import { handleResponse } from '../../util/helper';

export const beforeLogin = () => {
    return {
        type: BEFORE_LOGIN
    }
}

export const registerAction = (payload) => dispatch => {
    fetch(`${ENV.url}auth/register`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(handleResponse)
        .then(data => {
            if (data.status) {
                dispatch({
                    type: REGISTER_ACTION,
                    payload: data.message
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data
                })
            }
        }).catch(errors => {
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        })
}

export const loginAction = (payload) => dispatch => {
    fetch(`${ENV.url}auth/login`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(handleResponse)
        .then(data => {
            if (data.success) {
                localStorage.setItem('accessToken', data.data.accessToken)
                ENV.encryptUserData(data.data);
                dispatch({
                    type: LOGIN_ACTION,
                    payload: data
                });
            } else {
                swal({
                    text: data.message,
                    icon: "warning",
                    button: "OK!",
                });
                dispatch({
                    type: GET_ERRORS,
                    payload: data
                })
            }
        }).catch(errors => {
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        })
}
export const loginViaAdminAction = (payload) => dispatch => {
    fetch(`${ENV.url}auth/login-via-admin`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(handleResponse)
        .then(data => {
            if (data.success) {
                localStorage.setItem('accessToken', data.data.accessToken)
                ENV.encryptUserData(data.data);
                dispatch({
                    type: LOGIN_ACTION,
                    payload: data
                });
            } else {
                swal({
                    text: data.message,
                    icon: "warning",
                    button: "OK!",
                });
                dispatch({
                    type: GET_ERRORS,
                    payload: data
                })
            }
        }).catch(errors => {
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        })
}

export const verifyEmail = (userId) => dispatch => {
    const url = `${ENV.url}auth/verify-email/${userId}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
            'user-platform': 2 // 2 = admin
        }
    }).then(handleResponse).then(data => {
        if (data.success && data.data.isPasswordEmpty) {
            swal({
                text: data.message,
                icon: "success",
                button: "OK",
            });
            dispatch({
                type: SET_CUSTOMER_PASSWORD,
                payload: data.data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const setPasswordAction = (payload) => dispatch => {
    fetch(`${ENV.url}auth/set-password`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(handleResponse).then(data => {
        if (data.success) {
            swal({
                text: data.message,
                icon: "success",
                button: "OK",
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const forgetPassword = (payload) => dispatch => {
    fetch(`${ENV.url}auth/forgot-password`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(handleResponse).then(data => {
        if (data.success) {
            swal({
                text: data.message,
                icon: "success",
                button: "OK",
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const resetPassword = (payload) => dispatch => {
    fetch(`${ENV.url}auth/reset-password`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(payload)
    }).then(handleResponse).then(data => {
        if (data.success) {
            swal({
                text: data.message,
                icon: "success",
                button: "OK",
            });
        } else {
            swal({
                text: data.message,
                icon: "error",
            });
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        swal({
            text: errors,
            icon: "error",
        });
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getUserCart = (userId) => (dispatch) => {
    fetch(`${ENV.url}abandoned-cart/get-user-cart/${userId}`, {
        method: "GET",
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        }
    })
        .then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: CART_RETURNED,
                    payload: data.abandonedCart,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};
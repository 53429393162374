import {
    EMPTY_ERRORS,
    GET_ERRORS,
    BEFORE_CART,
    VALIDATE_COUPON,
    INVALIDATE_COUPON,
    CART_ADDED,
    CART_NOT_ADDED,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS,
    };
};

export const beforeCart = () => {
    return {
        type: BEFORE_CART,
    };
};

export const validateCoupon = (body) => (dispatch) => {
    dispatch(emptyError());
    const accessToken = localStorage.getItem('accessToken')
    fetch(`${ENV.url}shop/validate-coupon`, {
        method: "POST",
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': accessToken,
        },
        body: JSON.stringify(body)
    })
        .then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: VALIDATE_COUPON,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: INVALIDATE_COUPON,
                    payload: data.data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const addToUserCart = (body) => (dispatch) => {
    dispatch(emptyError());
    const accessToken = localStorage.getItem('accessToken')
    fetch(`${ENV.url}abandoned-cart/create`, {
        method: "POST",
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': accessToken,
        },
        body: JSON.stringify(body)
    }).then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: CART_ADDED,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: CART_NOT_ADDED,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};
import React from "react";
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./ProductBtn.css";

const ProductBtn = () => {

	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	return (
		<Row className="justify-content-center product-btn">
			<Link to={`/shop/${lang}`} className="view-main-btn text-center">
				<button type="button" className="main-btn">{translations["View All Products"]}</button>
			</Link>
		</Row>
	)
}

export default ProductBtn;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { LanguageProvider } from "./context/language"
import './index.css';

ReactDOM.createRoot((document.getElementById('root'))).render(
    <LanguageProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </LanguageProvider>
)
import {
    EMPTY_ERRORS,
    GET_ERRORS,
    BEFORE_PROFILE,
    UPSERT_USER,
    UPSERT_EXTRACT,

} from "../../redux/types";
import { ENV } from "../../config/config";
import axios from "axios";
import swal from 'sweetalert'
import { handleResponse } from "../../util/helper";

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS,
    };
};

export const beforeProfile = (data) => {
    return {
        type: BEFORE_PROFILE,
    };
};

export const sendEmailAgain = (userId) => dispatch => {
    fetch(`${ENV.url}auth/send-email-again/${userId}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),

        },
    }).then(handleResponse)
        .then(data => {
            if (data.success) {
                swal({
                    text: data.message,
                    icon: "success",
                    button: "OK",
                });
                window.location.reload()
            } else {
                swal({
                    text: data.message,
                    icon: "error",
                });
                dispatch({
                    type: GET_ERRORS,
                    payload: data
                })
            }
        }).catch(errors => {
            swal({
                text: errors,
                icon: "error",
            });
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        })
}

export const updateProfile = (userID, body) => (dispatch) => {
    dispatch(emptyError());
    fetch(`${ENV.url}user/${userID}`, {
        method: "PUT",
        headers: {
            Authorization: ENV.Authorization,
            'Content-Type': "application/json",
            "x-auth-token": ENV.xAuthToken,
            'x-token': localStorage.getItem('accessToken'),
        },
        body: JSON.stringify(body)
    })
        .then(handleResponse)
        .then((data) => {
            if (data.success) {
                dispatch({
                    type: UPSERT_USER,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch((errors) => {
            dispatch({
                type: GET_ERRORS,
                payload: errors,
            });
        });
};

export const uploadExtract = (e = {}) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}user/upload-extract`;
    const data = new FormData();
    data.append('extractFile', e.target.files[0]);
    axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${ENV.Authorization}`,
            'x-auth-token': ENV.xAuthToken
        }
    })
        .then(data => {
            if (data.data.success) {
                dispatch({
                    type: UPSERT_EXTRACT,
                    payload: data.data
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: data,
                });
            }
        })
        .catch(error => {
            dispatch({
                type: GET_ERRORS,
                payload: error
            })
        })
}
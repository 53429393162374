import React from "react";
import { Container } from 'react-bootstrap';
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./Cyber.css";

const Cyber = () => {

    const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	return (
		<section className="about-us-banner-bottom custom-container">
			<Container fluid>
				<h2>{translations["Our heart"]}</h2>
				<p><b>{translations["Our visions"]}</b> {translations["Our vision"]}</p>
				<span>{translations["Our business"]}</span>
			</Container>
		</section>
	)
}


export default Cyber;
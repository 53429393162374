import { combineReducers } from 'redux'
import faqReducer from "../component/faq/faq.reducer";
import productReducer from "../component/product/product.reducer";
import siteSettingReducer from "../component/siteSetting/siteSetting.reducer";
import cartReducer from "../component/cart/cart.reducer";
import checkoutReducer from "../component/checkout/checkout.reducer";
import contactReducer from "../component/contactUs/contactUs.reducer";
import errorReducer from "./shared/error/error.reducer";
import loginReducer from "../component/login/login.reducer";
import bannerReducer from "../component/banner/banner.reducer";
import dashboardReducer from "../component/dashboard/dashboard.reducer";
import profileReducer from "../component/userProfile/userProfile.reducer";
import contentReducer from "../component/content/content.reducer";
import cmsReducer from "../component/cms/cms.reducer";

export default combineReducers({
    errors: errorReducer,
    product: productReducer,
    faq: faqReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    contact: contactReducer,
    siteSetting: siteSettingReducer,
    login: loginReducer,
    banner: bannerReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    content: contentReducer,
    cms: cmsReducer
})
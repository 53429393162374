import React from 'react'
import './template.css'
const Index =()=> {
    return (

        <div className='col-lg-6'>
            <form>
                <div className="mb-3 input-wrapper">
                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3 input-wrapper">
                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3 input-wrapper">
                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-4 input-wrapper">
                    <textarea className="form-control" placeholder="Lorem ipsum" id="floatingTextarea2" rows="5"></textarea>
                </div>
                <button type="submit" className="btn-blue">Lorem</button>
            </form>
        </div>
    )
}

export default Index
import React from "react";
import { Container } from 'react-bootstrap';
import SpecialityImg from '../../asset/image/about-us-section.jpg'
import SpecialityFastImg from '../../asset/image/about-speciality-fast.svg'
import SpecialityCcImg from '../../asset/image/about-speciality-cc.svg'
import SpecialityDuplicateImg from '../../asset/image/about-speciality-duplicate.svg'
import SpecialityCameraImg from '../../asset/image/about-speciality-camera.svg'
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { useLanguage } from "../../context/language";
import "./aboutSpeciality.css";

const AboutSpeciality = () => {
	const { lang } = useLanguage()
	const translations = new LocalizedStrings(localizations,{logsEnabled: false});;
	translations.setLanguage(lang);

	return (
		<section className="about-us-speciality py-5">
			<Container fluid>
				<div className="about-us-speciality-img text-center d-inline-block">
					<img src={SpecialityImg} className="img-fluid " alt="about-us" title="" />
				</div>
				<div className="about-us-speciality-main">
					<ul className="list-unstyled mt-3 mb-3">
						<li className="d-flex">
							<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
								<div className="speciality-icon-bg-img">
									<img src={SpecialityFastImg} alt="icon" className="img-fluid" />
								</div>
							</div>
							<div className="about-us-speciality-text">
								<h3>{translations["Excellence"]}</h3>
								<p>{translations["Excellences"]}.</p>
							</div>
						</li>
						<li className="d-flex">
							<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
								<div className="speciality-icon-bg-img">
									<img src={SpecialityCcImg} alt="icon" className="img-fluid" />
								</div>
							</div>
							<div className="about-us-speciality-text">
								<h3>{translations["Listen"]}</h3>
								<p>{translations["Listens"]}</p>
							</div>
						</li>
						<li className="d-flex">
							<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
								<div className="speciality-icon-bg-img">
									<img src={SpecialityDuplicateImg} alt="icon" className="img-fluid" />
								</div>
							</div>
							<div className="about-us-speciality-text">
								<h3>{translations["Sparring"]}</h3>
								<p>{translations["Sparrings"]}</p>
							</div>
						</li>
						<li className="d-flex">
							<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
								<div className="speciality-icon-bg-img">
									<img src={SpecialityCameraImg} alt="icon" className="img-fluid" />
								</div>
							</div>
							<div className="about-us-speciality-text">
								<h3>{translations["Quality"]}</h3>
								<p>{translations["Qualities"]}</p>
							</div>
						</li>
					</ul>
				</div>
			</Container>
		</section>
	)
}

export default AboutSpeciality;
import Landing from '../component/landing/Landing';
import Shop from '../component/shop/Shop';
import AboutUs from '../component/aboutUs/aboutUs';
import ContactUs from '../component/contactUs/contactUs';
import ProductDetail from '../component/productDetail/productDetail';
import Cart from '../component/cart/cart';
import Checkout from '../component/checkout/checkout';
import Login from '../component/login/Login';
import AdminLoginRequest from '../component/login/AdminLoginRequest';
import Register from '../component/register/Register';
import PrivacyPolicy from '../component/privacyPolicy/privacyPolicy';
import TermsOfUse from '../component/termsOfUse/termsOfUse';
import NotFound from '../component/notFound/notFound';
import EmailVerified from "../component/register/EmailVerified";
import ForgetPassword from "../component/login/ForgetPassword";
import ResetPassword from "../component/login/ResetPassword";
import CMS from '../component/cms/cms';

export const publicRoutes = [
    {
        path: '/verify-email/:userId/:de',
        element: <EmailVerified />
    },
    {
        path: '/:de',
        element: <Landing />
    },
    {
        path: '/shop/:de',
        element: <Shop />
    },
    {
        path: '/about-us/:de',
        element: <AboutUs />
    },
    {
        path: '/contact-us/:de',
        element: <ContactUs />
    },
    {
        path: '/product-detail/:id/:de',
        element: <ProductDetail />
    },
    {
        path: '/cart/:de',
        element: <Cart />
    },
    {
        path: '/checkout/:de',
        element: <Checkout />
    },
    {
        path: '/login/:de',
        element: <Login />
    },
    {
        path: '/register/:de',
        element: <Register />
    },
    {
        path: '/privacy-policy/:de',
        element: <PrivacyPolicy />
    },
    {
        path: '/terms-of-use/:de',
        element: <TermsOfUse />
    },
    {
        path: '/not-found/:de',
        element: <NotFound />
    },
    {
        path: '/forget-password/:de',
        element: <ForgetPassword />
    },
    {
        path: '/reset-password/:de',
        element: <ResetPassword />
    },
    {
        path: '/page/:slug/:de',
        element: <CMS />
    },
    {
        path: '/admnlgncust/:userId/:accessToken',
        element: <AdminLoginRequest />
    },
];
